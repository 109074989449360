import {
  Box,
  ButtonBase,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { COLORS } from 'src/theme';
import {
  Flex,
  TextField,
  Typography,
  BuyerLogo,
  DatePicker,
} from 'src/components';
import { alpha } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { InvoiceDataType } from '.';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/HighlightOff';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useParams } from 'react-router-dom';
import { toggleSnackbar } from 'src/redux';
import { useDispatch } from 'react-redux';
type InvoiceFormDetails = {
  invoiceObject: InvoiceDataType;
  index: number;
  onDelete: (index: number) => void;
  onChange: (name: string, value: string, index: number) => void;
  onFileUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  removeImage: (index: number) => void;
  onPartySearch: (value: string, inde: number) => void;
};

const InvoiceForm = ({
  invoiceObject,
  index,
  onDelete,
  onChange,
  onFileUpload,
  removeImage,
  onPartySearch,
}: InvoiceFormDetails) => {
  const { spacing, palette } = useTheme();
  const [searchInputValue, setSearchInputValue] = useState('');
  const { userId: userIdParam } = useParams();
  const dispatch = useDispatch();
  const onSearch = (input: string, index: number) => {
    onPartySearch(input, index);
  };

  const handleInvoiceAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const inputValue = event.target.value;
    if (inputValue === '' || /^\d+$/.test(inputValue)) {
      onChange('invoiceAmount', inputValue, index);
    } else {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: 'Please enter a valid amount.',
          snackBarType: 'error',
        })
      );
    }
  };
  return (
    <Box
      className="grid grid-cols-2 gap-6 m-4 p-5 relative"
      sx={{
        border: `1px solid ${COLORS.LIGHT_GRAY}`,
        borderRadius: '0.4rem',
      }}
    >
      {index > 0 && (
        <DeleteIcon
          className="absolute right-8 top-8 cursor-pointer"
          sx={{ color: 'red' }}
          onClick={() => onDelete(index)}
        />
      )}
      <Box className="col-span-1">
        <Typography fontWeight="bold" className="mb-6">
          Invoice - {index + 1}
        </Typography>
        <Box className="mt-3">
          <Typography fontWeight="bold">
            Upload the invoice from your device
          </Typography>
        </Box>
        {invoiceObject.s3Url ? (
          <Box
            sx={{
              height: '320px',
              my: spacing(2),
              border: `dashed 1px ${COLORS.PRIMARY}`,
              position: 'relative',
            }}
          >
            <ClearIcon
              className="absolute  -right-3 -top-3 text-red-700 cursor-pointer"
              onClick={() => removeImage(index)}
            />
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              className="h-full"
              sx={{
                overflow: 'hidden',
              }}
            >
              <embed src={invoiceObject.s3Url} width="100%" height="100%" />
            </Flex>
          </Box>
        ) : (
          <Box
            sx={{
              background: alpha(COLORS.PRIMARY, 0.1),
              border: `dashed 1px ${COLORS.PRIMARY}`,
              height: '320px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: spacing(2),
              position: 'relative',
              borderRadius: '0.2rem',
              color: COLORS.PRIMARY,
            }}
          >
            <FileUploadIcon /> Select invoice to upload from your device
            <input
              className="absolute opacity-0 inset-0 cursor-pointer"
              type={'file'}
              onChange={(event) => onFileUpload(event, index)}
              accept="application/pdf, image/*"
            />
          </Box>
        )}
      </Box>

      <Box className="col-span-1">
        {!userIdParam && (
          <Box
            sx={{ border: `1px solid ${COLORS.LIGHT_GRAY}`, borderRadius: 2 }}
            className="p-2 mb-4"
          >
            <Box>
              <Flex alignItems={'center'} justifyContent={'flex-start'}>
                <AccountCircleIcon fontSize="large" color="disabled" />
                <Box className="mx-1">
                  <Typography fontWeight="bold" className="!mt-1">
                    Party/Buyer name
                  </Typography>
                  <Typography fontSize="0.8rem" color={COLORS.GHOST_GRAY}>
                    Enter your buyer’s GST or Mobile Number{' '}
                  </Typography>
                </Box>
              </Flex>
              <Box sx={{ my: spacing(1) }}>
                <Box className="relative">
                  <>
                    <TextField
                      value={invoiceObject.buyerGST}
                      onChange={(event) =>
                        onChange('buyerGST', event.target.value, index)
                      }
                      placeholder="Search"
                      className="absolute"
                      fullWidth
                    />
                    <ButtonBase
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        right: 15,
                        transform: 'translateY(-50%)',
                        color: palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: '12px',
                      }}
                      onClick={() => onSearch(invoiceObject.buyerGST, index)}
                    >
                      Search
                    </ButtonBase>
                  </>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {invoiceObject.partyName && (
          <>
            <Typography fontWeight="bold" className="!mt-1">
              Party/Buyer name
            </Typography>
            <Typography fontSize="0.8rem" className="!mt-1 !mb-3">
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={invoiceObject.partyName} />
                {invoiceObject.partyName}
              </Flex>
            </Typography>
          </>
        )}
        <TextField
          labelText="Enter Invoice number"
          value={invoiceObject.invoiceNumber}
          onChange={(event) =>
            onChange('invoiceNumber', event.target.value, index)
          }
          placeholder="Enter Invoice Number"
          name="invoiceNumber"
          sx={{ mb: spacing(1) }}
          fullWidth
          required
        />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={false}
          name="radio-buttons-group"
          row
          onChange={(event) => onChange('', event.target.value, index)}
        >
          <FormControlLabel
            value={false}
            control={
              <Radio
                sx={{
                  fontWeight: 'bold',
                }}
              />
            }
            label={<Typography fontWeight="bold">Full Amount</Typography>}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={<Typography fontWeight="bold">Partial Amount </Typography>}
          />
        </RadioGroup>
        <TextField
          value={invoiceObject.invoiceAmount}
          onChange={(event) => handleInvoiceAmountChange(event, index)}
          placeholder="Enter Full Amount"
          name="invoiceAmount"
          sx={{ mb: spacing(1) }}
          fullWidth
          type="tel"
          required
        />
        <Typography fontWeight="bold" className="!mt-1">
          Enter Invoice date
        </Typography>
        <DatePicker
          value={
            invoiceObject.invoiceDate !== ''
              ? new Date(
                  invoiceObject.invoiceDate.split('/').reverse().join('-') // Note important Convert to 'dd-mm-yyyy'
                )
              : null
          }
          onChange={(name, value) => {
            onChange(
              name,
              value instanceof Date ? value.toLocaleDateString('en-IN') : '', //The instanceof operator tests to see if the prototype property of a constructor
              index
            );
          }}
          name="invoiceDate"
        />
      </Box>
    </Box>
  );
};

export default InvoiceForm;
