import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { toggleSnackbar } from 'src/redux';
import { useAppSelector } from 'src/redux';
import {
  getSnackBarSelector,
  getSnackBarMessageSelector,
  getSnackBarTypeSelector,
} from 'src/redux/slices';

const SnackBar = () => {
  const dispatch = useDispatch();
  const showSnackBar = useAppSelector(getSnackBarSelector);
  const snackBarMessage = useAppSelector(getSnackBarMessageSelector);
  const snackbarType = useAppSelector(getSnackBarTypeSelector);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      toggleSnackbar({
        showSnackBar: false,
        snackBarMessage: '',
        snackBarType: 'success',
      })
    );
  };

  return (
    <Snackbar open={showSnackBar} autoHideDuration={4000} onClose={handleClose}>
      <Alert
        elevation={6}
        variant='filled'
        onClose={handleClose}
        color={snackbarType}
      >
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default SnackBar;
