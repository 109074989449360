import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import { TextField, Button } from 'src/components';
import { Search } from '@mui/icons-material';
import ButtonBase from '@mui/material/ButtonBase';
import Switch from '@mui/material/Switch';
import {
  toggleLoader,
  useAppDispatch,
  useLazySearchBuyerByGSTQuery,
  useSendInvitationByGSTMutation,
  toggleSnackbar,
  useLazyFetchBuyersQuery,
} from 'src/redux';
import { useAppSelector } from 'src/redux';
import { getSupplierId } from 'src/redux';
import { SearchByGSTDataType } from 'src/types';
import {
  API_STATUS_CODE,
  BUYERS_SEARCH_BY_ENUM,
  BUYERS_SEARCH_VALUE_ENUM,
} from 'src/constants';
import { ERRORS } from 'src/constants';
import { COLORS } from 'src/theme';

type SingleInviteType = {
  closeModal: () => void;
};

export default function SingleInvite({ closeModal }: SingleInviteType) {
  //HOOKS
  const [singleInviteObject, setSingleInviteObject] =
    useState<SearchByGSTDataType>({
      buyerGstin: '',
      buyerName: '',
      buyerMobileNumber: '',
      buyerEmail: '',
      invitationStatus: '',
      averageSales: '',
    });
  const { spacing, palette } = useTheme();
  const [triggerSearchBuyerQuery] = useLazySearchBuyerByGSTQuery();
  const [sendInvitationQuery] = useSendInvitationByGSTMutation();
  const supplierId = useAppSelector(getSupplierId);
  const [isGstApiSuccess, setIsGstApiSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const [isNoGst, setIsNoGst] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [fetchBuyers] = useLazyFetchBuyersQuery();

  //CHANGE HANDLER
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setSingleInviteObject((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const onGSTSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await triggerSearchBuyerQuery({
      GSTNumber: buyerGstin?.toUpperCase(),
      supplierId,
    }).unwrap();

    if (response) {
      setIsGstApiSuccess(true);
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.GST_FETCHED,
          snackBarType: 'success',
        })
      );
      setSingleInviteObject(response);
    } else {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.GST_NOT_FETCHED,
          snackBarType: 'error',
        })
      );
    }
  };

  const onInviteSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(toggleLoader(true));
    try {
      const response = await sendInvitationQuery({
        avgSales: averageSales ? Number(averageSales) : null,
        buyerEmailId: buyerEmail ? buyerEmail : null,
        buyerGstin: !isNoGst ? buyerGstin : '', //without gst toggle button handle this part
        buyerMobileNumber: buyerMobileNumber,
        buyerName: buyerName,
        supplierId: supplierId,
      }).unwrap();

      if (response.code === API_STATUS_CODE.OK) {
        fetchBuyers({
          pageNumber,
          searchBy: BUYERS_SEARCH_BY_ENUM.INVITATION_TYPE,
          searchValue: BUYERS_SEARCH_VALUE_ENUM.INVITE,
          supplierId,
        });
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: ERRORS.INVITE_SEND,
            snackBarType: 'success',
          })
        );

        closeModal();
      } else {
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: response?.message,
            snackBarType: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.SOMETHING_WRONG,
          snackBarType: 'error',
        })
      );
    }
    dispatch(toggleLoader(false));
  };

  const noGstHandler = () => {
    setIsNoGst(!isNoGst);
  };
  // VALUES
  const {
    buyerName,
    buyerMobileNumber,
    buyerEmail,
    invitationStatus,
    buyerGstin,
    averageSales,
  } = singleInviteObject;
  return (
    <Box my={spacing(4)}>
      <Box>
        <ButtonBase
          sx={{
            color: COLORS.BLACK,
            fontSize: '0.9rem',
            fontWeight: '600',
            fontFamily: 'Poppins,sans-serif',
          }}
        >
          Invite Without GST
        </ButtonBase>
        <Switch defaultChecked={false} color="primary" onClick={noGstHandler} />
      </Box>
      {!isNoGst && (
        <>
          <Box sx={{ position: 'relative' }}>
            <form onSubmit={onGSTSearch}>
              <TextField
                labelText="Enter GST*"
                value={buyerGstin?.toUpperCase()}
                onChange={onInputChange}
                placeholder="Enter Party GST"
                name="buyerGstin"
                fullWidth
                required
                sx={{ mb: spacing(2) }}
                startIcon={<Search sx={{ color: palette.primary.main }} />}
              />
              <ButtonBase
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 15,
                  transform: 'translateY(-25%)',
                  color: palette.primary.main,
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                Search
              </ButtonBase>
            </form>
          </Box>
        </>
      )}

      {(isGstApiSuccess || isNoGst) && (
        <>
          <form onSubmit={onInviteSend}>
            <TextField
              labelText="Company Name*"
              value={buyerName || ''}
              onChange={onInputChange}
              placeholder="Enter Company Name"
              name="buyerName"
              fullWidth
              sx={{ mb: spacing(2) }}
              required
            />
            <TextField
              labelText="Enter Mobile Number*"
              value={buyerMobileNumber || ''}
              onChange={onInputChange}
              placeholder="Enter Mob No."
              name="buyerMobileNumber"
              fullWidth
              type="tel"
              maxLength={10}
              sx={{ mb: spacing(2) }}
              required
            />
            <TextField
              labelText="Enter Email ID"
              value={buyerEmail || ''}
              onChange={onInputChange}
              placeholder="Enter Email ID"
              name="buyerEmail"
              sx={{ mb: spacing(2) }}
              fullWidth
              type="email"
            />
            <TextField
              labelText="Avg monthly Sales*"
              value={averageSales || ''}
              onChange={onInputChange}
              placeholder="Enter Avg monthly sales"
              name="averageSales"
              sx={{ mb: spacing(2) }}
              fullWidth
              required
              type="tel"
            />
            <Button
              sx={{
                mt: spacing(2),
              }}
              // disabled={!!invitationStatus}
              type="submit"
            >
              Invite
            </Button>
          </form>
        </>
      )}
    </Box>
  );
}
