import { Box, CircularProgress } from '@mui/material';
import { Flex } from 'src/components';

type LoaderProps = {
  show: boolean;
};

const Loader = ({ show }: LoaderProps) => {
  if (!show) {
    return null;
  }

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: 'lightgray',
          opacity: 0.5,
        }}
      />
      <CircularProgress color='primary' />
    </Flex>
  );
};

export default Loader;
