import { useTheme } from '@mui/material';
import { Flex } from 'src/components';

type BuyerLogoProps = {
  name?: string | null;
};
const BuyerLogo = ({ name }: BuyerLogoProps) => {
  const { palette, spacing } = useTheme();
  const companyLogo = name
    ?.split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 2);

  return (
    <Flex
      sx={{
        border: `1px solid ${palette.lightGray}`,
        padding: spacing(1),
        borderRadius: '100%',
        height: spacing(4),
        width: spacing(4),
        minHeight: spacing(3),
        minWidth: spacing(3),
        fontSize: 12,
        color: `${palette.primary.main}`,
        fontWeight: 'medium',
      }}
      alignItems="center"
      justifyContent="center"
    >
      {name ? companyLogo : 'NA'}
    </Flex>
  );
};

export default BuyerLogo;
