import { Typography, BuyerLogo, Flex } from 'src/components';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { INVITATION_STATUS_PARAMS, BUYER_STATUS_PARAMS } from 'src/constants';
import { useTheme } from '@mui/material';
import { COLORS } from 'src/theme/constants';

export const staggedHeaders = [
  {
    label: 'Party Name',
    id: 'buyerName',
    render: ({ row }) => {
      return (
        <Typography fontSize="0.8rem">
          {row?.buyerName ? (
            <Flex className="gap-2" alignItems={'center'}>
              <BuyerLogo name={row?.buyerName} />
              {row?.buyerName}
            </Flex>
          ) : (
            '-'
          )}
        </Typography>
      );
    },
  },
  {
    label: 'Mobile Number',
    id: 'mobileNumber',
  },
  {
    label: 'GSTIN',
    id: 'buyerGstIn',
    render: ({ row }) => {
      return <div>{row?.buyerGstIn || 'NA'}</div>;
    },
  },
  {
    label: 'Status',
    id: 'invitationStatus',
    render: ({ row }) => {
      const { palette } = useTheme();
      return (
        <>
          <Flex className="gap-2" alignItems={'center'}>
            {row?.buyerStatus === BUYER_STATUS_PARAMS.COMPLETED ? (
              <>
                <CheckCircleIcon
                  fontSize="medium"
                  sx={{
                    color: palette?.lightGreen,
                  }}
                />
                <div>Onboarded</div>
              </>
            ) : row?.buyerStatus === BUYER_STATUS_PARAMS.PENDING ? (
              <>
                <AccessTimeFilledIcon
                  fontSize="medium"
                  sx={{
                    color: COLORS.ORANGE,
                  }}
                />
                <div>Onboarding Pending</div>
              </>
            ) : null}
          </Flex>
          <div className="pt-2">{row?.invitationDate}</div>
        </>
      );
    },
  },
];
