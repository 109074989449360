import { styled, createSvgIcon } from '@mui/material';

const ArrowLeftTop = createSvgIcon(
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.15021 6.73098L17.0181 17.5989'
      stroke='currentColor'
      strokeWidth='2.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8444 6.73098L6.15001 6.73098L6.15001 15.4253'
      stroke='currentColor'
      strokeWidth='2.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'ArrowLeftTop'
);

export default styled(ArrowLeftTop)(({ fontSize, color = 'primary' }) => ({
  color: color,
  fontSize: `${fontSize}px !important`,
}));
