import { Box, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Button, Flex, TextField, Typography, Card } from 'src/components';
import { API_STATUS_CODE, ERRORS, REQUEST_SOURCE } from 'src/constants';
import { withPageInternals } from 'src/hoc';
import {
  useAppSelector,
  getMobileNumber,
  setUserBEData,
  getUser,
  useAppDispatch,
  toggleSnackbar,
  toggleLoader,
} from 'src/redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { HOME, INDEX } from 'src/utils';
import { ArrowBack } from '@mui/icons-material';

const OTP = () => {
  //HOOKS

  const { spacing } = useTheme();
  const [otp, setOtp] = useState('');
  const mobileNumber = useAppSelector(getMobileNumber);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  if (!user.userBEData.userId && !user.mobileNumber) {
    return <Navigate to={INDEX} />;
  }

  //HANDLER
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtp(value);
  };

  // TODO add snackbar and move errors in a constant file
  const onOtpSend = () => {
    if (otp.length !== 4) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.OTP_LENGTH,
          snackBarType: 'error',
        })
      );
      return;
    }
    dispatch(toggleLoader(true));
    const dataToSend = {
      deviceId: null,
      mobileNumber,
      otp,
      requestSource: REQUEST_SOURCE,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_PEMANT}/user-management/v1/otp/validate`,
        dataToSend
      )
      .then(({ data }) => {
        dispatch(toggleLoader(false));
        if (data.code === API_STATUS_CODE.OK) {
          dispatch(setUserBEData(data.data));
          navigate(HOME);
        } else {
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: data.message ?? ERRORS.INVALID_OTP,
              snackBarType: 'error',
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: ERRORS.SOMETHING_WRONG,
            snackBarType: 'error',
          })
        );
        console.error(err);
      });
  };

  //UI
  return (
    <Card smallWidth>
      <Flex alignItems="center">
        <ArrowBack
          onClick={() => {
            navigate(INDEX);
          }}
          sx={{ cursor: 'pointer' }}
          fontSize="small"
        />
        <Flex flexGrow={1} justifyContent="center">
          <Typography fontWeight="bold">Login</Typography>
        </Flex>
      </Flex>

      <Box mt={spacing(4)}>
        <Typography
          sx={{
            marginBottom: spacing(2),
          }}
        >
          OTP sent to {mobileNumber}
        </Typography>
        <TextField
          labelText="Enter OTP"
          value={otp}
          onChange={onInputChange}
          fullWidth
          placeholder="Enter OTP"
          type="number"
        />
        <Button
          variant="contained"
          sx={{
            my: spacing(2),
          }}
          onClick={onOtpSend}
        >
          Login
        </Button>
      </Box>
    </Card>
  );
};

export default withPageInternals(OTP);
