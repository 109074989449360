/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColorEnum } from 'src/types';

export const getColorFromTheme = ({ palette }: any, colorEnum: ColorEnum) => {
  // TODO - Will fix this any
  if (colorEnum === 'primary' || colorEnum === 'secondary') {
    return palette[colorEnum].main;
  }

  return palette[colorEnum];
};
