import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

export type UserBEType = {
  token: string;
  userId: string;
  invalidOtpCount: null;
  supplierId: string;
  userType: string;
};

interface UserState {
  mobileNumber: string;
  userBEData: UserBEType;
}

const initialState = {
  mobileNumber: '',
  userBEData: {
    token: '',
    userId: '',
    invalidOtpCount: null,
    supplierId: '',
    userType: '',
  },
} as UserState;

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMobileNumber(state, { payload }: PayloadAction<string>) {
      state.mobileNumber = payload;
    },
    setUserBEData(state, { payload }: PayloadAction<UserBEType>) {
      state.userBEData = payload;
    },
    clearUserData(state) {
      state.userBEData = {
        token: '',
        userId: '',
        invalidOtpCount: null,
        supplierId: '',
        userType: '',
      };
      state.mobileNumber = '';
      storage.removeItem('persist:root')
    },
  },
});

export const { setMobileNumber, setUserBEData, clearUserData } =
  usersSlice.actions;
