import { RootState } from 'src/redux/store';

export const getLoaderSelector = (state: RootState) =>
  state.internals.showLoader;

export const getSnackBarSelector = (state: RootState) =>
  state.internals.showSnackBar;

export const getSnackBarMessageSelector = (state: RootState) =>
  state.internals.snackBarMessage;

export const getSnackBarTypeSelector = (state: RootState) =>
  state.internals.snackBarType;
