import React, { useEffect, useState } from 'react';
import {
  PageHeading,
  FetchingSkeleton,
  Table,
  TextField,
  Card,
} from 'src/components';
import { useTheme, alpha } from '@mui/system';
import {
  API_STATUS_CODE,
  Filters as ActivePartiesFilter,
  ERRORS,
  FILTERS_DROPDOWN_ARRAY,
} from 'src/constants';
import {
  useLazyFetchActivePartiesQuery,
  getSupplierId,
  useAppSelector,
  useAppDispatch,
  toggleSnackbar,
} from 'src/redux';
import { withPageInternals } from 'src/hoc';
import { headers } from './config';
import { Box, ButtonBase, Select, SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { BuyersType } from 'src/types';
import { RowPropsType } from 'src/components/common/Table';
import { useNavigate } from 'react-router-dom';
import { BUYER_DETAIL } from 'src/utils';

function ActiveParties() {
  const { spacing, palette } = useTheme();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchByValue, setSearchByValue] = useState<
    ActivePartiesFilter | string
  >('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const supplierId = useAppSelector(getSupplierId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setPageNumber(1);
    activePartiesFetch({
      pageNumber: 1,
      supplierId,
      searchBy: searchByValue,
      searchValue: searchInputValue,
    });
  }, []);

  const [
    activePartiesFetch,
    { data: activePartiesData, isFetching: isActivePartiesFetching },
  ] = useLazyFetchActivePartiesQuery();

  const onFilterChange = (event: SelectChangeEvent) => {
    setSearchByValue(event.target.value as ActivePartiesFilter);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value?.trim());
  };

  const onSearch = () => {
    if (searchByValue) {
      setPageNumber(1);
      activePartiesFetch({
        pageNumber: 1,
        supplierId,
        searchBy: searchByValue,
        searchValue: searchInputValue,
      });
    } else {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: 'Please select search type',
          snackBarType: 'error',
        })
      );
    }
  };

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    activePartiesFetch({
      pageNumber: value,
      supplierId,
      searchBy: searchByValue,
      searchValue: searchInputValue,
    });
  };

  const resetFilters = () => {
    setSearchInputValue('');
    setSearchByValue('');
    activePartiesFetch({
      pageNumber: 1,
      supplierId,
      searchBy: '',
      searchValue: '',
    });
  };

  useEffect(() => {
    // TODO Fix unknown issue
    const statusCode = activePartiesData?.code as unknown as number;
    if (statusCode === API_STATUS_CODE.BACKEND_ERROR) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: activePartiesData?.message as unknown as string,
          snackBarType: 'error',
        })
      );
    }
  }, [activePartiesData?.code]);

  const onRowClick = (row: BuyersType) => {
    navigate(`${BUYER_DETAIL}/${row.userId}`);
  };

  return (
    <Box className="pb-20">
      <Card>
        <PageHeading>
          Active Parties(
          {activePartiesData?.data?.list?.length || 0})
        </PageHeading>
        <Box className="my-8 flex gap-x-4">
          <Select
            value={searchByValue}
            onChange={onFilterChange}
            sx={{
              '.MuiSelect-select': {
                padding: spacing(2),
                width: spacing(30),
                background: 'white',
              },
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              <em>Search By </em>
            </MenuItem>
            {FILTERS_DROPDOWN_ARRAY.map((dropdownVal) => {
              return (
                <MenuItem value={dropdownVal.value} key={dropdownVal.value}>
                  {dropdownVal.label}
                </MenuItem>
              );
            })}
          </Select>
          <Box className="relative">
            <TextField
              value={searchInputValue || ''}
              onChange={onInputChange}
              placeholder="Search"
              className="absolute"
              onKeyDown={(event) => {
                //This event is triggered every time the user presses down any key while selecting the text input field.
                if (event.key === 'Enter') {
                  event.preventDefault(); // Prevent the form from submitting
                  onSearch(); // Call the onSearch function when Enter key is pressed
                }
              }}
              sx={{
                width: '300px',
              }}
              type={searchByValue === 'mobileNumber' ? 'number' : 'text'}
            />
            <ButtonBase
              sx={{
                position: 'absolute',
                top: '50%',
                right: 15,
                transform: 'translateY(-50%)',
                color: palette.primary.main,
                fontWeight: 'bold',
                fontSize: '12px',
              }}
              onClick={onSearch}
            >
              Search
            </ButtonBase>
          </Box>
          <Box
            sx={{
              borderRadius: 5,
              p: `${spacing(0.5)} ${spacing(1.5)}`,
              mr: spacing(1.2),
              fontSize: '1rem',
              fontWeight: 'bold',
              color: palette.primary.main,
              backgroundColor: alpha(palette.primary.main, 0.2),
              cursor: 'pointer',
              height: '2.2rem',
              marginTop: '0.6rem',
            }}
            onClick={resetFilters}
          >
            Reset Filter
          </Box>
        </Box>

        {isActivePartiesFetching ? (
          <FetchingSkeleton />
        ) : (
          <Table
            columns={headers}
            rows={activePartiesData?.data?.list || []}
            onRowClick={({ row }: RowPropsType<BuyersType>) => {
              onRowClick(row);
            }}
            paginationComponent={
              <Pagination
                count={activePartiesData?.data?.totalPages}
                page={pageNumber}
                onChange={handleChange}
              />
            }
          />
        )}
      </Card>
    </Box>
  );
}

export default withPageInternals(ActiveParties);
