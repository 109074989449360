import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { Typography } from 'src/components';
import { COLORS } from 'src/theme';
import { ColorConstantEnum } from 'src/types';

type IndicatorsProps = {
  color: ColorConstantEnum;
  indicatorText?: string;
  textBold?: boolean;
  wrapperClass?: string;
};

const Indicators = ({
  color,
  indicatorText,
  textBold,
  wrapperClass,
}: IndicatorsProps) => {
  const { spacing } = useTheme();

  return (
    <Box className={`flex items-center ${wrapperClass}`}>
      <Box
        sx={{
          height: spacing(2),
          width: spacing(2),
          borderRadius: '100%',
          background: COLORS[color],
          mr: spacing(1),
        }}
      />
      {indicatorText && (
        <Typography fontWeight={textBold ? 'bold' : 'medium'}>
          {indicatorText}
        </Typography>
      )}
    </Box>
  );
};

export default Indicators;
