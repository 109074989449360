import {
  SupplierIdType,
  ProfileData,
  ProfileDataResponseType,
  ProgramDataResponseType,
  ProgramDataType,
} from 'src/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiBEQuery,baseApiBEQueryC1 } from 'src/utils';

export const profileApi = createApi({
  reducerPath: 'profile',
  baseQuery: baseApiBEQuery,
  endpoints: (builder) => ({
    fetchSupplierProfileDetails: builder.query<ProfileData, SupplierIdType>({
      query: ({ supplierId }) => ({
        url: `user-management/v1/supplier/fetch/details/${supplierId}`,
        method: 'GET',
      }),
      transformResponse: (response: ProfileDataResponseType) => {
        return response.data;
      },
    }),
  }),
});

export const profileApiC1 = createApi({
  reducerPath: 'profile-c1',
  baseQuery: baseApiBEQueryC1,
  endpoints: (builder) => ({
    fetchSupplierProgramDetails: builder.query<ProgramDataType, SupplierIdType>(
      {
        query: ({ supplierId }) => ({
          url: `invoice-management/v2/dashboard/fetch/programs/${supplierId}`,
          method: 'GET',
        }),
        transformResponse: (response: ProgramDataResponseType) => {
          return response.data;
        },
      }
    ),
  }),
});


export const {
  useFetchSupplierProfileDetailsQuery,
} = profileApi;

export const {
  useFetchSupplierProgramDetailsQuery,
} = profileApiC1;