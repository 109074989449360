import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InternalsState {
  showLoader: boolean;
}

interface InternalSnackbarState {
  showSnackBar: boolean;
  snackBarMessage: string;
  snackBarType: 'error' | 'warning' | 'info' | 'success';
}

const initialState = {
  showLoader: false,
} as InternalsState;

const initialSnackbarState = {
  showSnackBar: false,
  snackBarMessage: '',
  snackBarType: 'success',
} as InternalSnackbarState;

export const internalsSlice = createSlice({
  name: 'internals',
  initialState: { ...initialState, ...initialSnackbarState },
  reducers: {
    toggleLoader(state, { payload }: PayloadAction<boolean>) {
      state.showLoader = payload;
    },
    toggleSnackbar(state, { payload }: PayloadAction<InternalSnackbarState>) {
      const { showSnackBar, snackBarMessage, snackBarType } = payload;
      state.showSnackBar = showSnackBar;
      state.snackBarMessage = snackBarMessage;
      state.snackBarType = snackBarType;
    },
  },
});

export const { toggleLoader, toggleSnackbar } = internalsSlice.actions;
