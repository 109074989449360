import { Typography, BuyerLogo, Flex } from 'src/components';
import { rupeeDecimalFormatter } from 'src/utils';
import { useTheme } from '@mui/material';
import { BUYER_LIMIT_STATUS_CAPSULE } from 'src/constants';

export const headers = [
  {
    label: 'Party Name',
    id: 'partyName',
    render: ({ row }) => {
      return (
        <Typography fontSize="0.8rem">
          {row?.partyName ? (
            <Flex className="gap-2" alignItems={'center'}>
              <BuyerLogo name={row?.partyName} />
              {row?.partyName}
            </Flex>
          ) : (
            '-'
          )}
        </Typography>
      );
    },
  },
  {
    label: 'Mobile Number',
    id: 'mobileNumber',
    render: ({ row }) => {
      return <div>{row?.mobileNumber || '-'}</div>;
    },
  },
  {
    label: 'Limit Approved',
    id: 'limitApproved',
    render: ({ row }) => {
      if (typeof row?.limitApproved === 'number') {
        return <div>{rupeeDecimalFormatter(row?.limitApproved)}</div>;
      }
      return <div>{row?.limitApproved || '-'}</div>;
    },
  },
  {
    label: 'Balance Limit',
    id: 'balanceLimit',
    render: ({ row }) => {
      if (typeof row?.balanceLimit === 'number') {
        return <div>{rupeeDecimalFormatter(row?.balanceLimit)}</div>;
      }
      return <div>{row?.balanceLimit || '-'}</div>;
    },
  },
  {
    label: 'Used Limit',
    id: 'usedLimit',
    render: ({ row }) => {
      if (typeof row?.usedLimit === 'number') {
        return <div>{rupeeDecimalFormatter(row?.usedLimit)}</div>;
      }
      return <div>{row?.usedLimit || '-'}</div>;
    },
  },
  {
    label: 'Limit Status',
    id: 'limitStatus',
    render: ({ row }) => {
      const { spacing } = useTheme();
      const capsuleHandler = (type) => {
        return BUYER_LIMIT_STATUS_CAPSULE?.map((item) => {
          return item.status === row?.limitStatus ? item[type] : 'transparent';
        }).find((value) => value !== 'transparent');
      };

      return (
        <Flex>
          <Typography
            sx={{
              justifyContent: 'center',
              borderRadius: 1,
              padding: '4px 5px',
              p: `${spacing(0.6)} ${spacing(1.4)}`,
              fontSize: '1rem',
              color: `${capsuleHandler('colour')}`,
              backgroundColor: `${capsuleHandler('backgroundColor')}`,
              fontWeight: 'regular',
              fontSize: '1.4rem',
              cursor: 'pointer',
              minWidth: '6rem',
              textAlign: 'center',
            }}
          >
            {row?.limitStatus}
          </Typography>
        </Flex>
      );
    },
  },
];
