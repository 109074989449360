import { Box } from '@mui/material';
import FileUpload from 'src/assets/images/file-upload.png';
import { COLORS } from 'src/theme';
import { useState } from 'react';
import { Dialog } from 'src/components';

import { UploadInvoices } from 'src/components/Modals';

const UploadFloatingButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onUploadClick = (e: boolean) => {
    setIsModalOpen(e);
  };
  return (
    <>
      <Box
        className="fixed bottom-10 right-10 rounded-full p-2 cursor-pointer"
        sx={{ background: COLORS.PRIMARY }}
        onClick={() => onUploadClick(true)}
      >
        <img src={FileUpload} height={50} width={50} />
      </Box>
      <Dialog
        open={isModalOpen}
        onClose={() => onUploadClick(false)}
        heading="Upload Invoice"
        maxWidth="lg"
        shouldPaddingHide
      >
        <UploadInvoices closeModal={onUploadClick} />
      </Dialog>
    </>
  );
};

export default UploadFloatingButton;
