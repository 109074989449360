import { Typography } from 'src/components';
import { useTheme, Grid, alpha } from '@mui/material';
import { Box } from '@mui/system';
import { InvoicesType, BuyerInvoicesType } from 'src/types';
import { rupeeDecimalFormatter } from 'src/utils';
import { Flex } from 'src/components';
import { COLORS } from 'src/theme';
type InvitationCardProps = {
  invoiceData: InvoicesType | BuyerInvoicesType | null;
};
export const PaidCardModal = ({ invoiceData }: InvitationCardProps) => {
  const { spacing, palette } = useTheme();
  const bgColor = (margin: string | null | undefined) => {
    if (margin === 'Released') {
      return `${alpha(COLORS.DARK_BOTTLE_GREEN, 0.2)}`;
    } else {
      return `${alpha(COLORS.ORANGE, 0.1)}`;
    }
  };

  const getMarginStatusColor = (marginStatus: string | null | undefined) => {
    if (marginStatus === 'Released') {
      return `${alpha(COLORS.DARK_BOTTLE_GREEN, 0.3)}`;
    } else if (marginStatus === 'On Hold') {
      return `${alpha(COLORS.ORANGE, 0.3)}`;
    } else {
      return '';
    }
  };
  const getStatusColor = (marginStatus: string | null | undefined) => {
    if (marginStatus === 'Released') {
      return COLORS.DARK_BOTTLE_GREEN;
    } else if (marginStatus === 'On Hold') {
      return COLORS.ORANGE;
    } else {
      return '';
    }
  };

  const preSign = (value: number, type: string): string => {
    // subvention and margin is a type to show + and - according
    if (value > 0) {
      if (type === 'margin') {
        return '+';
      }
      return '-';
    } else {
      return '';
    }
  };

  return (
    <Box my={spacing(2)}>
      <Grid
        container
        gap={spacing(4)}
        sx={{
          my: spacing(2),
        }}
      >
        <Grid item xs={5}>
          {invoiceData?.invoiceFileUrl?.includes('pdf') ? (
            <embed
              src={invoiceData?.invoiceFileUrl || ''}
              width="100%"
              height="500px"
              style={{ borderRadius: '0.5rem' }}
            />
          ) : (
            <img
              src={invoiceData?.invoiceFileUrl || ''}
              alt="Invoice File Url"
              style={{ borderRadius: '0.5rem' }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            gap={spacing(2)}
            sx={{
              mb: spacing(1),
              border: `1px solid ${COLORS.LIGHT_GRAY}`,
              px: spacing(2),
              py: spacing(1),
              borderRadius: 2,
              backgroundColor: `${alpha(palette.lightGray, 0.1)}`,
            }}
          >
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Amount</Typography>
              <Typography>
                {rupeeDecimalFormatter(Number(invoiceData?.invoiceAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Date</Typography>
              <Typography>{invoiceData?.invoiceDate || '-'}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Subvention Amount</Typography>
              <Typography color="red" sx={{ display: 'flex' }}>
                {preSign(Number(invoiceData?.subventionAmount), 'subvention')}{' '}
                {rupeeDecimalFormatter(Number(invoiceData?.subventionAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">UTR Number</Typography>
              <Typography>{invoiceData?.utrNumber || '-'}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Margin Amount</Typography>
              <Typography color="red" sx={{ display: 'flex' }}>
                {preSign(Number(invoiceData?.marginAmount), 'subvention')}{' '}
                {rupeeDecimalFormatter(Number(invoiceData?.marginAmount))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Payment Date</Typography>
              <Typography>{invoiceData?.paymentDate || '-'}</Typography>
            </Grid>
          </Grid>

          {invoiceData?.margin && (
            <Grid
              container
              sx={{
                backgroundColor: bgColor(invoiceData?.margin?.status),
                borderRadius: 2,
              }}
            >
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <Typography fontWeight="bold" fontSize="0.8rem">
                    Margin Amount
                  </Typography>
                  <Flex alignItems={'center'}>
                    <Typography
                      color={getStatusColor(invoiceData?.margin?.status)}
                      sx={{ display: 'flex' }}
                    >
                      {preSign(Number(invoiceData?.marginAmount), 'margin')}{' '}
                      {rupeeDecimalFormatter(
                        Number(invoiceData?.margin?.amount)
                      )}
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: 5,
                        p: `${spacing(0.5)} ${spacing(1.5)}`,
                        mx: spacing(0.5),
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        color: getStatusColor(invoiceData?.margin?.status),
                        backgroundColor: getMarginStatusColor(
                          invoiceData?.margin?.status
                        ),
                        borderColor: getStatusColor(
                          invoiceData?.margin?.status
                        ),
                      }}
                    >
                      {invoiceData?.margin?.status}
                    </Box>
                  </Flex>
                </Box>
              </Grid>
              <Grid item xs={5} container alignItems="center">
                <Grid container direction="column">
                  <Grid item mt={1}>
                    <Box>
                      <Typography fontWeight="bold" fontSize="0.8rem">
                        UTR No
                      </Typography>
                      <Typography>{invoiceData?.margin?.utr || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item mb={2}>
                    <Box>
                      <Typography fontWeight="bold" fontSize="0.8rem">
                        Payment Date
                      </Typography>
                      <Typography>
                        {invoiceData?.margin?.paymentDate || '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            gap={spacing(2)}
            sx={{
              mt: spacing(1),
              border: `1px solid ${COLORS.LIGHT_GRAY}`,
              px: spacing(2),
              py: spacing(1),
              borderRadius: 2,
              backgroundColor: `${alpha(palette.lightGray, 0.1)}`,
            }}
          >
            <Grid item xs={10}>
              <Typography fontWeight="bold">Total Payment Amount</Typography>
              <Typography>
                {rupeeDecimalFormatter(Number(invoiceData?.paymentAmount))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const UnpaidCardModal = ({ invoiceData }: InvitationCardProps) => {
  const { spacing, palette } = useTheme();
  const preSign = (value: number): string => {
    if (value > 0) {
      return '-';
    } else {
      return '';
    }
  };
  const invoiceStatus = (status: string | null | undefined) => {
    if (status === 'drafted') {
      return 'Drafted';
    } else if (status === 'under_review') {
      return 'Under Review';
    } else if (status === 'on_hold') {
      return 'On Hold';
    } else if (status === 'rejected') {
      return 'Rejected';
    } else {
      return `${status ?? '-'}`;
    }
  };
  return (
    <Box my={spacing(2)}>
      <Grid
        container
        gap={spacing(4)}
        sx={{
          my: spacing(4),
        }}
      >
        <Grid item xs={5}>
          {invoiceData?.invoiceFileUrl?.includes('pdf') ? (
            <embed
              src={invoiceData?.invoiceFileUrl || ''}
              width="100%"
              height="500px"
              style={{ borderRadius: '0.5rem' }}
            />
          ) : (
            <img
              src={invoiceData?.invoiceFileUrl || ''}
              alt="Invoice File Url"
              style={{ borderRadius: '0.5rem' }}
            />
          )}
        </Grid>
        <Grid item xs={5}>
          <Grid
            container
            gap={spacing(4)}
            sx={{
              my: spacing(4),
              border: `1px solid ${COLORS.LIGHT_GRAY}`,
              px: spacing(2),
              py: spacing(1),
              borderRadius: 2,
              backgroundColor: `${alpha(palette.lightGray, 0.1)}`,
            }}
          >
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Amount</Typography>
              <Typography>
                {rupeeDecimalFormatter(Number(invoiceData?.invoiceAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Date</Typography>
              <Typography>{invoiceData?.invoiceDate || '-'}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Subvention Amount</Typography>
              <Typography color="red" sx={{ display: 'flex' }}>
                {preSign(Number(invoiceData?.subventionAmount))}{' '}
                {rupeeDecimalFormatter(Number(invoiceData?.subventionAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Status</Typography>
              <Typography color="red">
                {invoiceStatus(invoiceData?.invoiceStatus)}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography fontWeight="bold">Net Payable Amount</Typography>
              <Typography>
                {rupeeDecimalFormatter(Number(invoiceData?.paymentAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">UTR Number</Typography>
              <Typography>{invoiceData?.utrNumber || '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const FailedCardModal = ({ invoiceData }: InvitationCardProps) => {
  const { spacing, palette } = useTheme();
  const preSign = (value: number): string => (value > 0 ? '-' : '');
  return (
    <Box my={spacing(2)}>
      <Grid
        container
        gap={spacing(4)}
        sx={{
          my: spacing(4),
          border: `1px solid ${COLORS.LIGHT_GRAY}`,
          px: spacing(2),
          py: spacing(1),
          borderRadius: 2,
          backgroundColor: `${alpha(palette.lightGray, 0.1)}`,
        }}
      >
        <Grid item xs={5}>
          {invoiceData?.invoiceFileUrl?.includes('pdf') ? (
            <embed
              src={invoiceData?.invoiceFileUrl || ''}
              width="100%"
              height="500px"
              style={{ borderRadius: '0.5rem' }}
            />
          ) : (
            <img
              src={invoiceData?.invoiceFileUrl || ''}
              alt="Invoice File Url"
              style={{ borderRadius: '0.5rem' }}
            />
          )}
        </Grid>
        <Grid item xs={5}>
          <Grid
            container
            gap={spacing(4)}
            sx={{
              my: spacing(4),
            }}
          >
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Amount</Typography>
              <Typography>
                {rupeeDecimalFormatter(Number(invoiceData?.invoiceAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Invoice Date</Typography>
              <Typography>{invoiceData?.invoiceDate || '-'}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Subvention Amount</Typography>
              <Typography color="red" sx={{ display: 'flex' }}>
                {preSign(Number(invoiceData?.subventionAmount))}{' '}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Payment Date</Typography>
              <Typography>{invoiceData?.paymentDate || '-'}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography fontWeight="bold">Net Payable Amount</Typography>
              <Typography>
                {rupeeDecimalFormatter(Number(invoiceData?.paymentAmount))}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">Reason</Typography>
              <Typography>{invoiceData?.failReason || '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
