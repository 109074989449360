import { useTheme } from '@mui/material';
import { Box } from '@mui/system';

export type ContainerProps = {
  children: React.ReactNode;
};
const Container = ({ children }: ContainerProps) => {
  const { spacing, breakpoints } = useTheme();
  return (
    <Box
      sx={{
        m: `${spacing(4)} ${spacing(2)}`,
        mb: spacing(15),
        [breakpoints.up('md')]: {
          width: '80%',
          mx: 'auto',
        },
        [breakpoints.up('lg')]: {
          width: '85%',
          mx: 'auto',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
