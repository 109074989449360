import React, { useState, useEffect } from 'react';
import { useTheme, alpha, Grid, CardActionArea } from '@mui/material';
import { Box } from '@mui/system';
import {
  BuyerLogo,
  Card,
  FetchingSkeleton,
  Flex,
  NoDataAvailable,
  Typography,
  Dialog,
  Table,
} from 'src/components';
import {
  getSupplierId,
  useAppSelector,
  useLazyFetchBuyersQuery,
  useBuyerInvitationDataQuery,
} from 'src/redux';
import {
  BUYERS_SEARCH_BY_ENUM,
  BUYERS_SEARCH_VALUE_ENUM,
  INVITATIONS_FILTER_ARRAY,
} from 'src/constants';
import { BuyerType } from 'src/types';
import { rupeeDecimalFormatter } from 'src/utils';
import { staggedHeaders } from './config';
import Pagination from '@mui/material/Pagination';

type InvitationCardProps = {
  buyer: BuyerType;
};

export default function Stagged() {
  //HOOKS
  const { spacing, palette } = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const [currentFilter, setCurrentFilter] =
    React.useState<BUYERS_SEARCH_VALUE_ENUM>(BUYERS_SEARCH_VALUE_ENUM.ALL);
  const supplierId = useAppSelector(getSupplierId);

  useEffect(() => {
    fetchBuyers({
      pageNumber,
      searchBy: BUYERS_SEARCH_BY_ENUM.BUYER_STATUS,
      searchValue: currentFilter,
      supplierId,
    });
  }, [currentFilter, pageNumber]);

  const [
    fetchBuyers,
    { data: invitationsData, isFetching: isInvitationDataFetching },
  ] = useLazyFetchBuyersQuery();

  // HANDLERS
  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const onToggleChange = (
    _: React.MouseEvent<HTMLDivElement>,
    filterName: BUYERS_SEARCH_VALUE_ENUM
  ) => {
    setCurrentFilter(filterName);
  };

  if (isInvitationDataFetching) {
    return <FetchingSkeleton />;
  }

  return (
    <>
      <Flex sx={{ my: spacing(2) }}>
        {INVITATIONS_FILTER_ARRAY.map((filterName) => {
          const isActive = filterName.value === currentFilter;
          return (
            <Box
              sx={{
                border: `1px solid ${
                  isActive ? palette.primary.main : palette.lightGray
                }`,
                borderRadius: 10,
                p: `${spacing(0.5)} ${spacing(2.5)}`,
                mr: spacing(2),
                fontSize: '1rem',
                color: `${isActive ? palette.primary.main : palette.ghostGray}`,
                backgroundColor: isActive
                  ? alpha(palette.primary.main, 0.2)
                  : 'transparent',
                cursor: 'pointer',
              }}
              onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                onToggleChange(
                  event,
                  filterName.value as BUYERS_SEARCH_VALUE_ENUM
                )
              }
              key={filterName.value}
            >
              {filterName.label}
            </Box>
          );
        })}
      </Flex>
      {invitationsData ? (
        <Table
          rows={invitationsData?.buyersList || []}
          columns={staggedHeaders}
          className="mt-4"
          paginationComponent={
            <Pagination
              count={invitationsData?.totalPages}
              page={pageNumber}
              onChange={handleChange}
            />
          }
        />
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

const InvitationCard = ({ buyer }: InvitationCardProps) => {
  const { spacing, palette } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const viewInvoice = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Card
        sx={{
          margin: `${spacing(1)} 0`,
        }}
        onClick={viewInvoice}
      >
        <Flex sx={{ margin: `${spacing(0)} 0` }}>
          <BuyerLogo name={buyer.buyerName} />
          <Box ml={spacing(1)}>
            <Flex>
              <Typography fontWeight="bold" color="primary">
                {buyer.buyerName}
              </Typography>
              <Typography
                sx={{
                  color: palette.gray,
                  ml: spacing(1),
                }}
                fontSize="0.75rem"
              >
                {buyer.invitationDate}
              </Typography>
            </Flex>
            <Typography>{buyer.buyerGstIn}</Typography>
          </Box>
        </Flex>
      </Card>
    </>
  );
};
