import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Button, Flex, TextField, Typography, Card } from 'src/components';
import { withPageInternals } from 'src/hoc';
import axios from 'axios';
import { API_STATUS_CODE, REQUEST_SOURCE } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { OTP_VERIFY } from 'src/utils';
import {
  useAppSelector,
  getMobileNumber,
  setMobileNumber,
  useAppDispatch,
  toggleSnackbar,
  toggleLoader,
} from 'src/redux';
import { ERRORS } from 'src/constants';

const Login = () => {
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const mobileNumber = useAppSelector(getMobileNumber);
  const dispatch = useAppDispatch();
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setMobileNumber(value.trim()));
  };

  const onGetOTP = () => {
    if (mobileNumber.length !== 10) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.MOBILE_NUMBER_ALERT,
          snackBarType: 'error',
        })
      );
      return;
    }

    const dataToSend = {
      mobileNumber,
      requestSource: REQUEST_SOURCE,
    };
    dispatch(toggleLoader(true));
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_PEMANT}/user-management/v1/otp/trigger`,
        dataToSend
      )
      .then(({ data }) => {
        dispatch(toggleLoader(false));
        if (data.code === API_STATUS_CODE.OK) {
          navigate(OTP_VERIFY);
        } else {
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: ERRORS.USER_NOT_REGISTER,
              snackBarType: 'error',
            })
          );
        }
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: ERRORS.SOMETHING_WRONG,
            snackBarType: 'error',
          })
        );
        console.error(err);
      });
  };

  return (
    <Card smallWidth>
      <Flex justifyContent="center">
        <Typography fontWeight="bold">Login</Typography>
      </Flex>
      <Box mt={spacing(4)}>
        <TextField
          labelText="Enter Mobile Number"
          value={mobileNumber}
          onChange={onInputChange}
          fullWidth
          placeholder="Enter Mobile Number"
          type="number"
        />
        <Button
          variant="contained"
          sx={{
            my: spacing(2),
          }}
          onClick={onGetOTP}
        >
          Get OTP
        </Button>
      </Box>
    </Card>
  );
};

export default withPageInternals(Login);
