export enum SORTING_PARAMS {
  TODAY = 'today',
  WEEK = 'one_week',
  MONTH = 'one_month',
  TILL_NOW = 'till_now',
}

export const SORTING_PARAMS_KEY_MAP = {
  [SORTING_PARAMS.TODAY]: 'Today',
  [SORTING_PARAMS.WEEK]: 'Week',
  [SORTING_PARAMS.MONTH]: 'Month',
  [SORTING_PARAMS.TILL_NOW]: 'Till Now',
};

export const SORTING_DROPDOWN = [
  {
    label: SORTING_PARAMS_KEY_MAP[SORTING_PARAMS.TODAY],
    value: SORTING_PARAMS.TODAY,
  },
  {
    label: SORTING_PARAMS_KEY_MAP[SORTING_PARAMS.WEEK],
    value: SORTING_PARAMS.WEEK,
  },
  {
    label: SORTING_PARAMS_KEY_MAP[SORTING_PARAMS.MONTH],
    value: SORTING_PARAMS.MONTH,
  },
  {
    label: SORTING_PARAMS_KEY_MAP[SORTING_PARAMS.TILL_NOW],
    value: SORTING_PARAMS.TILL_NOW,
  },
];

export enum BUYERS_SEARCH_BY_ENUM {
  GST_BUYERS = 'gstBuyers',
  INVITATION_TYPE = 'invitationType',
  BUYER_STATUS = 'buyerStatus',
}

export enum BUYERS_SEARCH_VALUE_ENUM {
  INVITE = 'invite',
  REQUEST = 'request',
  ONBOARDING_PENDING = 'onboarding_pending',
  ONBOARDING_COMPLETED = 'onboarding_completed',
  ACTIVE = 'active',
  ALL = 'all',
}

export enum INVOICE_STATUS_PARAMS {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  ALL = 'all',
  PROCESSING = 'processing',
  REJECTED = 'rejected',
}

export enum INVITATION_STATUS_PARAMS {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export enum BUYER_STATUS_PARAMS {
  PENDING = 'onboarding_pending',
  COMPLETED = 'onboarding_completed',
}

export enum REPAYMENT_STATUS_PARAMS {
  DUE = 'due',
  OVERDUE = 'overdue',
  SETTLED = 'settled',
}
