import React, { useEffect } from 'react';
import { Box, ButtonBase, useTheme } from '@mui/material';
import { COLORS } from 'src/theme';
import { useState } from 'react';
import { Button, Flex, Typography } from 'src/components';
import { set } from 'lodash';
import { API_STATUS_MESSAGE, ERRORS } from 'src/constants';
import { alpha } from '@mui/system';
import {
  getAccessToken,
  getAccessUserId,
  getInvoiceFilters,
  getSupplierId,
  toggleSnackbar,
  useAppDispatch,
  useAppSelector,
  useFetchBuyerDetailsQuery,
  useLazyFetchAllInvoicesHistoryQuery,
} from 'src/redux';
import axios from 'axios';
import {
  useUploadInvoiceDataMutation,
  toggleLoader,
  useLazyFetchActivePartiesQuery,
} from 'src/redux';
import { API_STATUS_CODE } from 'src/constants';
import InvoiceForm from './InvoiceForm';
import { INVOICE_UPLOAD_MEDIUM_ARRAY } from 'src/constants';
import { useParams, useLocation } from 'react-router-dom';

export type InvoiceDataType = {
  invoiceAmount: string;
  invoiceDate: string;
  invoiceId: string;
  invoiceNumber: string;
  buyerGST: string;
  partialPayment: boolean;
  userId: string;
  mediumType: string; // Make this a Enum,
  s3Url: string;
  partyName: string;
};

type UploadModalType = {
  closeModal: (e: boolean) => void;
};

const UploadInvoices = ({ closeModal }: UploadModalType) => {
  const invoiceEmptyObject = {
    invoiceAmount: '',
    invoiceDate: '',
    invoiceId: '',
    invoiceNumber: '',
    partialPayment: false,
    userId: '',
    buyerGST: '',
    mediumType: INVOICE_UPLOAD_MEDIUM_ARRAY[0].value,
    s3Url: '',
    partyName: '',
  };
  const [emptyObject, setEmptyObject] = useState(invoiceEmptyObject);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDataType[]>([
    emptyObject,
  ]);
  const dispatch = useAppDispatch();
  const { spacing, palette } = useTheme();
  const [sendInvoicesQuery] = useUploadInvoiceDataMutation();
  const supplierId = useAppSelector(getSupplierId);
  const token = useAppSelector(getAccessToken);
  const userId = useAppSelector(getAccessUserId);
  const [activePartiesFetch, { data: activePartiesData }] =
    useLazyFetchActivePartiesQuery();
  const { userId: userIdParam } = useParams();
  const location = useLocation();
  const invoiceTab = location.pathname.replace('/', '');
  const { data: buyerData, refetch } = useFetchBuyerDetailsQuery(
    {
      supplierId,
      userId: userIdParam as string,
    },
    {
      skip: !userIdParam,
    }
  );

  const filters = useAppSelector(getInvoiceFilters);
  const {
    currentFilter,
    pageNumber,
    searchParam,
    searchValue,
    endDate,
    startDate,
  } = filters;

  const [invoicesFetch] = useLazyFetchAllInvoicesHistoryQuery();

  useEffect(() => {
    (async () => {
      let activePartiesData;
      if (invoiceTab.includes('buyer-detail')) {
        //if supplier is in individual buyer tab then api should call
        if (userId) {
          const response = await activePartiesFetch({
            pageNumber: 1,
            supplierId,
            searchBy: 'mobileOrGST',
            searchValue: buyerData?.buyerMobileNumber as string,
          });
          activePartiesData = response.data;
        }
      }
      setEmptyObject({
        ...emptyObject,
        partyName: activePartiesData?.data?.list[0].partyName || '',
      });
      setInvoiceDetails([
        {
          ...emptyObject,
          partyName: activePartiesData?.data?.list[0].partyName || '',
        },
      ]);
    })();
  }, []);

  function formatDate(dateString: string): string {
    if (dateString) {
      const [day, month, year] = dateString.split('-');
      return `${day}/${month}/${year}`;
    } else {
      return '';
    }
  }

  const onInvoiceSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    invoicesFetch({
      endDate: endDate ? endDate : '',
      pageNumber,
      searchParam,
      startDate: startDate ? startDate : '',
      type: currentFilter,
      searchValue,
      supplierId,
    });
    dispatch(toggleLoader(true));
    const hasEmptyPartyName = invoiceDetails.some(
      (item) => item.partyName === ''
    );
    if (!hasEmptyPartyName) {
      // check if any partyName is empty api should not be call
      const invoicesList = invoiceDetails.map(
        ({ s3Url, mediumType, ...keepAttributes }) => {
          let userId;
          if (invoiceTab.includes('active-parties/buyer-detail/')) {
            userId = activePartiesData?.data?.list[0]?.userId || ''; // when user is in active parties user Id is this else for bulk invoices it come from keepAttributes.
          } else {
            userId = keepAttributes?.userId || '';
          }
          return {
            invoiceAmount: Number(keepAttributes.invoiceAmount),
            invoiceDate: keepAttributes.invoiceDate,
            invoiceId: keepAttributes.invoiceId,
            invoiceNumber: keepAttributes.invoiceNumber.trim(),
            partialPayment: Boolean(keepAttributes.partialPayment),
            userId: userId,
          };
        }
      );
      try {
        const response = await sendInvoicesQuery({
          list: invoicesList,
          supplierId: supplierId,
        }).unwrap();
        if (response.code === API_STATUS_CODE.OK) {
          if (invoiceTab.includes('active-parties/buyer-detail/')) {
            refetch();
          }
          closeModal(false);
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: ERRORS.INVOICE_SEND,
              snackBarType: 'success',
            })
          );
        } else {
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: response?.message,
              snackBarType: 'error',
            })
          );
        }
      } catch (error) {
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: ERRORS.SOMETHING_WRONG,
            snackBarType: 'error',
          })
        );
      }
      dispatch(toggleLoader(false));
    } else {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.PARTY_NAME,
          snackBarType: 'error',
        })
      );
      dispatch(toggleLoader(false));
    }
  };

  const onFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.target.files;
    const formData = new FormData();
    if (files && files?.length > 0) {
      formData.append('file', files[0]);
    }
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/formData',
      'X-CLIENT-NAME': 'PSDU',
      'X-USER-ID': userId,
    };

    if (formData) {
      dispatch(toggleLoader(true));
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_PEMANT_C1}/invoice-management/v2/dashboard/invoice-image/${supplierId}`,
          formData,
          {
            headers,
          }
        )
        .then(({ data }) => {
          dispatch(toggleLoader(false));
          if (data.invoiceId) {
            const scannedData = data.scannedInvoiceResponse.data;
            set(invoiceDetails, `[${index}].invoiceId`, data.invoiceId);
            set(invoiceDetails, `[${index}].s3Url`, data.s3Url);
            if (scannedData) {
              set(
                invoiceDetails,
                `[${index}].invoiceAmount`,
                scannedData?.total !== null ? scannedData.total : ''
              );
              set(
                invoiceDetails,
                `[${index}].invoiceNumber`,
                scannedData?.invoiceNo !== null ? scannedData?.invoiceNo : ''
              );
              set(
                invoiceDetails,
                `[${index}].buyerGST`,
                scannedData?.buyerGST !== null ? scannedData.buyerGST : ''
              );
              set(
                invoiceDetails,
                `[${index}].invoiceDate`,
                scannedData?.invoiceDate !== null
                  ? formatDate(scannedData?.invoiceDate)
                  : ''
              );
            }
            setInvoiceDetails([...invoiceDetails]);
            dispatch(
              toggleSnackbar({
                showSnackBar: true,
                snackBarMessage:
                  data?.scannedInvoiceResponse?.data?.message || // fileupload message will come from backend
                  ERRORS.INVOICE_UPLOADED,
                snackBarType: 'success',
              })
            );
          } else {
            dispatch(
              toggleSnackbar({
                showSnackBar: true,
                snackBarMessage: data?.message,
                snackBarType: 'error',
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: ERRORS.SOMETHING_WRONG,
              snackBarType: 'error',
            })
          );
        });
    }
  };
  const onChange = (name: string, value: string, index: number) => {
    const invoiceCopy = [...invoiceDetails];
    invoiceCopy[index] = { ...invoiceCopy[index], [name]: value };
    setInvoiceDetails(invoiceCopy);
  };

  const onDelete = (index: number) => {
    const invoiceDetailsCopy = [...invoiceDetails];
    invoiceDetailsCopy.splice(index, 1); // Remove the invoice at the given index
    setInvoiceDetails(invoiceDetailsCopy);
  };

  const onAddInvoice = () => {
    setInvoiceDetails([...invoiceDetails, { ...emptyObject }]); //When we add a new invoice, make sure to create a fresh object and push it to the invoiceDetails array so it won't affect previous entry.
  };

  const removeImage = (index: number) => {
    const invoiceCopy = [...invoiceDetails];
    invoiceCopy[index] = { ...invoiceCopy[index], ...emptyObject }; //Remove the invoice Details at the given index
    setInvoiceDetails(invoiceCopy);
  };
  const onPartySearch = async (value: string, index: number) => {
    const { data: activePartiesData } = await activePartiesFetch({
      pageNumber: 1,
      supplierId,
      searchBy: 'mobileOrGST',
      searchValue: value?.trim(),
    });
    if (activePartiesData) {
      const invoiceCopy = [...invoiceDetails];
      invoiceCopy[index] = {
        ...invoiceCopy[index],
        partyName: activePartiesData?.data?.list[0].partyName || '',
        userId: activePartiesData?.data?.list[0].userId || '',
      };
      setInvoiceDetails(invoiceCopy);
    }
  };
  return (
    <form onSubmit={onInvoiceSend}>
      {invoiceDetails?.map((invoice, index) => {
        return (
          <InvoiceForm
            key={`invoice-form${index}`}
            onDelete={onDelete}
            index={index}
            invoiceObject={invoice}
            onChange={onChange}
            onFileUpload={onFileUpload}
            removeImage={removeImage}
            onPartySearch={onPartySearch}
          />
        );
      })}
      <ButtonBase
        sx={{
          color: palette.primary.main,
          fontWeight: 'bold',
          marginBottom: '1rem',
          px: spacing(2),
          mt: spacing(2),
        }}
        onClick={onAddInvoice}
      >
        + Add new invoices
      </ButtonBase>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: alpha(COLORS.PRIMARY, 0.1),
          padding: '0.5rem 1rem',
        }}
      >
        <Typography fontWeight="bold">
          {invoiceDetails.length} Invoices
        </Typography>
        <Box>
          <Button sx={{ px: spacing(2) }} type="submit">
            Send Request
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

export default UploadInvoices;
