import { ThemeProvider } from './theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  Login,
  OTP,
  Home,
  Invitations,
  Invoices,
  Profile,
  ActiveParties,
  Discount,
  BuyerDetails,
} from 'src/pages';
import {
  INDEX,
  HOME,
  INVITATIONS,
  INVOICES,
  OTP_VERIFY,
  PROFILE,
  ACTIVE_PARTIES,
  DISCOUNT,
  BUYER_DETAIL,
} from './utils';
import { Loader } from './components';
import { Snackbar } from './components';
import { useAppSelector } from './redux';
import { getLoaderSelector } from './redux/slices';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function App() {
  const isLoaderVisible = useAppSelector(getLoaderSelector);

  return (
    <ThemeProvider>
      <Router>
        <Loader show={isLoaderVisible} />
        <Snackbar />
        <Routes>
          <Route path={INDEX} element={<Login currentUser={1} />} />
          <Route path={OTP_VERIFY} element={<OTP currentUser={1} />} />
          <Route path={HOME} element={<Home currentUser={1} />} />
          <Route path={DISCOUNT} element={<Discount currentUser={1} />} />
          <Route
            path={ACTIVE_PARTIES}
            element={<ActiveParties currentUser={1} />}
          />
          <Route
            path={`${ACTIVE_PARTIES}/${BUYER_DETAIL}/:userId`}
            element={<BuyerDetails currentUser={1} />}
          />
          <Route path={INVITATIONS} element={<Invitations currentUser={1} />} />
          <Route path={INVOICES} element={<Invoices currentUser={1} />} />
          <Route path={PROFILE} element={<Profile currentUser={1} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

/** Tech Debts
 * Font size should be uniform and must not have more then 4 values xl, sm, md , lg, xl, 2xl
 */
