import { Flex, Table, Typography, FetchingSkeleton } from 'src/components';
import {
  getSupplierId,
  useAppSelector,
  useFetchInvoicesDetailsQuery,
} from 'src/redux';
import {
  InvoicesDetailsType,
  ListOfPaymentHistoryType,
  PaymentsType,
} from 'src/types';
import { rupeeDecimalFormatter } from 'src/utils';
import { TableColumnType } from 'src/components/common/Table';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { PAYMENT_STATUS } from 'src/constants';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

type MultipleInvoiceProps = {
  invoiceData: ListOfPaymentHistoryType | PaymentsType | null;
};

const MultipleInvoice = ({ invoiceData }: MultipleInvoiceProps) => {
  const supplierId = useAppSelector(getSupplierId);
  const { palette } = useTheme();
  const { data: invoicesDetailsData, isFetching: isInvoiceFetching } =
    useFetchInvoicesDetailsQuery({
      supplierId,
      invoiceNumbers: invoiceData?.listOfInvNumber || [],
    });

  if (isInvoiceFetching) {
    return <FetchingSkeleton />;
  }

  const columns: TableColumnType<InvoicesDetailsType>[] = [
    {
      label: 'Invoice Number',
      id: 'invoiceNumber',
    },
    {
      label: 'Uploaded By',
      id: 'uploadedBy',
      render: ({ row }) => {
        return <div className="capitalize">{row?.uploadedBy || '-'}</div>;
      },
    },
    {
      label: 'Payment Amount',
      id: 'paymentAmount',
      render: ({ value }) => {
        if (typeof value === 'number') {
          return <div>{rupeeDecimalFormatter(value)}</div>;
        }
        return <div>{value}</div>;
      },
    },
  ];

  return (
    <>
      {[invoiceData]?.map((row) => {
        return (
          <Box className="grid grid-cols-5 gap-4 my-12 mx-2">
            <Box className="col-span-1">
              <Typography fontWeight="bold">NAME</Typography>
              <Typography>{row?.partyName}</Typography>
            </Box>
            <Box className="col-span-1">
              <Typography fontWeight="bold">INVOICE NUMBER</Typography>
              <Flex className="gap-2 cursor-pointer">
                <Typography
                  className="max-w-[160px] truncate"
                  fontSize="0.8rem"
                >
                  {row?.listOfInvNumber?.map((e) => (
                    <Typography>{e}</Typography>
                  ))}
                </Typography>
              </Flex>
            </Box>
            <Box className="col-span-1">
              <Typography fontWeight="bold">PAYMENT TYPE</Typography>
              <Typography className="capitalize">{row?.paymentType}</Typography>
            </Box>
            <Box className="col-span-1">
              <Typography fontWeight="bold">PAYMENT AMOUNT</Typography>
              <Typography>
                {rupeeDecimalFormatter(row?.paymentAmount)}
              </Typography>
            </Box>
            <Box className="col-span-1">
              <Typography fontWeight="bold">UTR</Typography>
              <Typography>{row?.utrNumber}</Typography>
              <Flex className="gap-2" alignItems={'center'}>
                {row?.status === PAYMENT_STATUS.PROCESSED ? (
                  <>
                    <CheckCircleIcon
                      fontSize="medium"
                      sx={{
                        color: palette.lightGreen,
                      }}
                    />
                    <div>Processed</div>
                  </>
                ) : row?.status === PAYMENT_STATUS.PROCESSING ? (
                  <>
                    <AccessTimeFilledIcon
                      fontSize="medium"
                      sx={{
                        color: palette.orange,
                      }}
                    />
                    <div>Processing</div>
                  </>
                ) : null}
              </Flex>
            </Box>
          </Box>
        );
      })}
      <Table
        rows={invoicesDetailsData?.listOfInvoiceBreakup || []}
        columns={columns}
        className="mt-4"
      />
    </>
  );
};

export default MultipleInvoice;
