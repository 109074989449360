import Box from '@mui/material/Box';
import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material';
import TemplateDownload from './TemplateDownload';
import GSTInvite from './GSTInvite';

export default function BulkInvite() {
  const [selectedRadio, setSelectedRadio] = React.useState('pemantTemplate');
  const { spacing, palette } = useTheme();

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  const renderBulkInviteOption = () => {
    switch (selectedRadio) {
      case 'pemantTemplate':
        return <TemplateDownload />;
      case 'tally':
        return <TemplateDownload />;
      case 'inviteFromGst':
        return <GSTInvite />;
    }
  };

  return (
    <Box my={spacing(2)}>
      <FormControl
        sx={{
          borderBottom: `1px solid ${palette.gray}`,
          mb: spacing(2),
        }}
      >
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='pemantTemplate'
          name='radio-buttons-group'
          row
          onChange={onRadioChange}
        >
          <FormControlLabel
            value='pemantTemplate'
            control={<Radio />}
            label='Pemant Template'
          />
          <FormControlLabel value='tally' control={<Radio />} label='Tally' />
          <FormControlLabel
            value='inviteFromGst'
            control={<Radio />}
            label='Invite from GST'
          />
        </RadioGroup>
      </FormControl>
      {renderBulkInviteOption()}
    </Box>
  );
}
