import { alpha, Box, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
  BuyerLogo,
  Flex,
  Typography,
  Button,
  FetchingSkeleton,
  NoDataAvailable,
  TextField,
} from 'src/components';
import {
  getSupplierId,
  useAppDispatch,
  useAppSelector,
  useLazyFetchBuyersQuery,
  toggleLoader,
  useSendInvitationByGSTMutation,
  toggleSnackbar,
} from 'src/redux';
import { BUYERS_SEARCH_BY_ENUM } from 'src/constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BOX_SHADOW } from 'src/theme';
import { API_STATUS_CODE, ERRORS } from 'src/constants';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { BuyerGSTDataType, SearchByGSTDataType } from 'src/types';

type SingleBuyerDataType = {
  buyer: BuyerGSTDataType;
};

type InviteClickType = {
  onClick: React.MouseEventHandler;
  invitationStatus: string | null;
};

export default function GSTInvite() {
  const supplierId = useAppSelector(getSupplierId);
  const [pageNumber, setPageNumber] = useState(1);
  const [fetchBuyers, { data: buyersData, isFetching: isBuyersDataFetching }] =
    useLazyFetchBuyersQuery();

  useEffect(() => {
    fetchBuyers({
      pageNumber,
      searchBy: BUYERS_SEARCH_BY_ENUM.GST_BUYERS,
      searchValue: null,
      supplierId,
    });
  }, []);

  if (isBuyersDataFetching) {
    return <FetchingSkeleton />;
  }
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography fontSize="1rem">Contacts from GSTN</Typography>
      </Flex>
      {buyersData ? (
        buyersData?.buyersList?.map((buyer, index) => {
          return <SingleGstInvite buyer={buyer} key={`invite-${index}`} />;
        })
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

// Single accordian component
export const SingleGstInvite = ({ buyer }: SingleBuyerDataType) => {
  const { spacing, palette } = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [singleInviteObject, setSingleInviteObject] =
    useState<SearchByGSTDataType>({
      buyerGstin: buyer?.buyerGstIn,
      buyerName: buyer?.buyerName,
      buyerMobileNumber: '',
      buyerEmail: '',
      invitationStatus: buyer?.invitationStatus,
      averageSales: '',
    });
  const supplierId = useAppSelector(getSupplierId);
  const dispatch = useAppDispatch();
  const [sendInvitationQuery] = useSendInvitationByGSTMutation();
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setSingleInviteObject((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const onInviteSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(toggleLoader(true));

    try {
      const response = await sendInvitationQuery({
        avgSales: averageSales ? Number(averageSales) : null,
        buyerEmailId: buyerEmail,
        buyerGstin: buyerGstin,
        buyerMobileNumber: buyerMobileNumber,
        buyerName: buyerName,
        supplierId: supplierId,
      }).unwrap();
      // TODO add snackbar
      if (response.code === API_STATUS_CODE.OK) {
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: ERRORS.INVITE_SEND,
            snackBarType: 'success',
          })
        );
        setExpanded(false);
      } else {
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: response?.message,
            snackBarType: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.SOMETHING_WRONG,
          snackBarType: 'error',
        })
      );
    }
    dispatch(toggleLoader(false));
  };

  const { buyerName, buyerMobileNumber, buyerEmail, buyerGstin, averageSales } =
    singleInviteObject;

  return (
    <Accordion
      sx={{
        margin: `${spacing(2)} 0`,
        boxShadow: BOX_SHADOW,
        '&::before': {
          height: 0,
        },
      }}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <IconButton onClick={() => setExpanded(false)}>
              <CancelIcon
                fontSize="large"
                sx={{
                  color: alpha(palette.primary.main, 0.7),
                }}
              />
            </IconButton>
          ) : (
            <InviteButton
              onClick={() => setExpanded(true)}
              invitationStatus={buyer?.invitationStatus}
            />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <BuyerLogo name={buyer?.buyerName} />
            <Box ml={2}>
              <Typography fontWeight="bold" color="primary">
                {buyer?.buyerName}
              </Typography>
              <Typography> {buyer?.buyerGstIn}</Typography>
            </Box>
          </Flex>
        </Flex>
      </AccordionSummary>
      <AccordionDetails>
        <form
          onSubmit={(e) => {
            onInviteSend(e);
          }}
        >
          <TextField
            labelText="Enter Mobile Number*"
            value={buyerMobileNumber || ''}
            onChange={onInputChange}
            placeholder="Enter Mob No."
            name="buyerMobileNumber"
            fullWidth
            type="tel"
            maxLength={10}
            sx={{ mb: spacing(2) }}
            required
          />
          <TextField
            labelText="Enter Email ID"
            value={buyerEmail || ''}
            onChange={onInputChange}
            placeholder="Enter Email ID"
            name="buyerEmail"
            sx={{ mb: spacing(2) }}
            fullWidth
            type="email"
          />
          <TextField
            labelText="Avg monthly Sales *"
            value={averageSales || ''}
            onChange={onInputChange}
            placeholder="Enter Avg monthly sales"
            name="averageSales"
            sx={{ mb: spacing(2) }}
            fullWidth
            required
            type={'number'}
          />
          <Button
            type="submit"
            sx={{
              mt: spacing(2),
            }}
          >
            Invite
          </Button>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

// Invite button UI
export const InviteButton = ({
  onClick,
  invitationStatus,
}: InviteClickType) => {
  const { palette } = useTheme();
  return (
    <Box>
      <Button
        onClick={onClick}
        sx={{
          fontSize: '0.75rem',
          padding: 0.5,
          background: alpha(palette.primary.main, 0.3),
          color: palette.primary.main,
          ':hover': {
            bgcolor: alpha(palette.primary.main, 0.3),
            color: palette.primary.main,
          },
        }}
      >
        {invitationStatus === 'pending'
          ? 'Invite Again'
          : invitationStatus === 'accepted'
          ? 'Invite Accepted'
          : invitationStatus === 'rejected'
          ? 'Invite Rejected'
          : 'Invite'}
      </Button>
    </Box>
  );
};
