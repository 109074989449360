import DatePicker from 'react-date-picker';
import styles from './DatePicker.module.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

type DatePickerProps = {
  value: Date | null; // Change this to Date
  onChange: (name: string, value: Date | null) => void;
  name: string;
};

const AppDatePicker = ({ value, onChange, name }: DatePickerProps) => {
  return (
    <div className={styles.datepickerWrapper}>
      <DatePicker
        onChange={(date) => {
          if (date instanceof Date) {
            onChange(name, date);
          }
        }}
        value={value}
        className={` w-full`}
        name={name}
        calendarIcon={<CalendarTodayIcon color="primary" />}
        clearIcon={null}
        format="dd/MM/yyyy"
      />
    </div>
  );
};

export default AppDatePicker;
