import React, { useState, useEffect, useMemo } from 'react';
import { useTheme, alpha } from '@mui/material';
import { Box } from '@mui/system';
import {
  Button,
  BuyerLogo,
  Card,
  FetchingSkeleton,
  Flex,
  NoDataAvailable,
  Typography,
  Table,
} from 'src/components';
import {
  BUYERS_SEARCH_BY_ENUM,
  BUYERS_SEARCH_VALUE_ENUM,
  INVITATION_STATUS_PARAMS,
} from 'src/constants';
import {
  getSupplierId,
  toggleLoader,
  useAppDispatch,
  useAppSelector,
  useLazyFetchBuyersQuery,
  useLazyInviteAgainQuery,
  toggleSnackbar,
} from 'src/redux';
import { ERRORS } from 'src/constants';
import { BuyerType } from 'src/types';
import { TableColumnType } from 'src/components/common/Table';
import { COLORS } from 'src/theme/constants';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Pagination from '@mui/material/Pagination';

export default function Sent() {
  const { palette } = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const supplierId = useAppSelector(getSupplierId);
  const dispatch = useAppDispatch();
  const [
    fetchBuyers,
    { data: invitationsData, isFetching: isInvitationDataFetching },
  ] = useLazyFetchBuyersQuery();
  const [inviteAgainQuery] = useLazyInviteAgainQuery();

  useEffect(() => {
    fetchBuyers({
      pageNumber,
      searchBy: BUYERS_SEARCH_BY_ENUM.INVITATION_TYPE,
      searchValue: BUYERS_SEARCH_VALUE_ENUM.INVITE,
      supplierId,
    });
  }, [pageNumber]);

  if (isInvitationDataFetching) {
    return <FetchingSkeleton />;
  }

  //HANDLERS
  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const onInviteAgain = async (buyerMobileNumber: string) => {
    dispatch(toggleLoader(true));
    const response = await inviteAgainQuery({
      supplierId,
      buyerMobileNumber,
    }).unwrap();

    if (response) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.INVITE_SEND,
          snackBarType: 'success',
        })
      );
    } else {
    }
    dispatch(toggleLoader(false));
  };

  //Table Column
  const headers: TableColumnType<BuyerType>[] = [
    {
      label: 'Party Name',
      id: 'buyerName',
      render: ({ row }) => {
        return (
          <Typography fontSize="0.8rem">
            {row?.buyerName ? (
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={row?.buyerName} />
                {row?.buyerName}
              </Flex>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },

    {
      label: 'Mobile Number',
      id: 'mobileNumber',
    },
    {
      label: 'GSTIN',
      id: 'buyerGstIn',
      render: ({ row }) => {
        return <div>{row?.buyerGstIn || 'NA'}</div>;
      },
    },
    {
      label: 'Status',
      id: 'invitationStatus',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <>
            <Flex className="gap-2" alignItems={'center'}>
              {row?.invitationStatus === INVITATION_STATUS_PARAMS.ACCEPTED ? (
                <>
                  <CheckCircleIcon
                    fontSize="medium"
                    sx={{
                      color: palette?.lightGreen,
                    }}
                  />
                  <div>Onboarded</div>
                </>
              ) : row?.invitationStatus === INVITATION_STATUS_PARAMS.PENDING ? (
                <>
                  <div>Invitation sent</div>
                </>
              ) : null}
            </Flex>
            <div className="pt-2">{row?.invitationDate}</div>
          </>
        );
      },
    },
    {
      label: 'Invitation',
      id: 'invitationStatus',
      render: ({ row }) => {
        return (
          <Button
            sx={{
              fontSize: '0.75rem',
              padding: 0.5,
              background: alpha(palette.primary.main, 0.1),
              color: palette.primary.main,
              ':hover': {
                bgcolor: alpha(palette.primary.main, 0.3),
                color: palette.primary.main,
              },
              minWidth: 'max-content',
            }}
            onClick={() => onInviteAgain(row.mobileNumber)}
          >
            Invite Again
          </Button>
        );
      },
    },
  ];

  return (
    <>
      {invitationsData ? (
        <Table
          rows={invitationsData?.buyersList || []}
          columns={headers}
          className="mt-4"
          paginationComponent={
            <Pagination
              count={invitationsData?.totalPages}
              page={pageNumber}
              onChange={handleChange}
            />
          }
        />
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}
