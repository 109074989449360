import MuiDialog from '@mui/material/Dialog';
import { Typography } from 'src/components';
import { Close } from '@mui/icons-material';
import { Box, SxProps, useTheme } from '@mui/material';
export type DialogProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  heading?: React.ReactNode;
  sx?: SxProps;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  shouldPaddingHide?: boolean;
};

const Dialog = ({
  children,
  open,
  onClose,
  heading,
  sx,
  maxWidth = 'md',
  fullWidth = true, // This true as we will have full width of Modal for the mobile screens
  shouldPaddingHide = false,
}: DialogProps) => {
  const { spacing, palette } = useTheme();
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={sx}
    >
      <Box
        sx={{
          position: 'relative',
          borderBottom: heading ? `1px solid ${palette.lightGray}` : null,
          px: spacing(4),
          py: spacing(0.75),
        }}
      >
        {heading && (
          <Typography fontWeight="bold" fontSize="1.25rem">
            {heading}
          </Typography>
        )}
      </Box>
      <Close
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 15,
          top: 15,
          cursor: 'pointer',
        }}
      />
      <Box sx={shouldPaddingHide ? {} : { px: spacing(2), py: spacing(1) }}>
        {children}
      </Box>
    </MuiDialog>
  );
};

export default Dialog;
