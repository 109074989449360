import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { Card, Flex, Typography } from 'src/components';
import { COLORS } from 'src/theme/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { rupeeDecimalFormatter } from 'src/utils';
import { ProgramData } from 'src/types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BOX_SHADOW } from 'src/theme';

export type ProgramDetailsProps = {
  program: ProgramData;
};

const Program = ({ program }: ProgramDetailsProps) => {
  const { spacing } = useTheme();
  return (
    <>
      <Card
        sx={{
          marginBottom: spacing(2),
        }}
      >
        <Flex
          justifyContent='space-between'
          sx={{
            pb: spacing(0.5),
            mb: spacing(2),
            borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,
          }}
        >
          <Typography color='primary' fontSize='1.1rem'>
            Program
          </Typography>
        </Flex>
        <Grid
          container
          gap={spacing(2)}
          sx={{
            my: spacing(1),
          }}
        >
          <Grid item xs={11}>
            <Typography fontWeight='bold'>Program Name</Typography>
            <Typography>{program?.programName}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight='bold'>Overall Credit Exposure</Typography>
            <Typography>
              {rupeeDecimalFormatter(program?.supplierOverallLimit)}{' '}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight='bold'>Limit Utilized</Typography>
            <Typography>
              {rupeeDecimalFormatter(program?.utilizedLimit)}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight='bold'>Program Start</Typography>
            <Typography>{program?.startDate}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight='bold'>Program End</Typography>
            <Typography>{program?.endDate}</Typography>
          </Grid>
        </Grid>
      </Card>
      <Card
        sx={{
          marginBottom: spacing(2),
        }}
      >
        <Flex
          justifyContent='space-between'
          sx={{
            pb: spacing(0.5),
            mb: spacing(2),
            borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,
          }}
        >
          <Typography color='primary' fontSize='1.1rem'>
            Sub-Programs
          </Typography>
        </Flex>
        {program?.subPrograms?.map((subProgram, index) => {
          return (
            <Accordion
              sx={{
                margin: `${spacing(2)} 0`,
                boxShadow: BOX_SHADOW,
                '&::before': {
                  height: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                key={`SubProgram ${index}`}
              >
                <Flex direction='column'>
                  <Typography fontWeight='bold'>Sub-Program Name</Typography>
                  <Typography>{subProgram?.subProgramName}</Typography>
                </Flex>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  gap={spacing(2)}
                  sx={{
                    my: spacing(1),
                  }}
                >
                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Overall Limit</Typography>
                    <Typography>
                      {' '}
                      {rupeeDecimalFormatter(subProgram?.minLimit)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Status</Typography>
                    <Typography>{subProgram?.status}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Sub Program Start</Typography>
                    <Typography>{subProgram?.startDate}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Sub Program End</Typography>
                    <Typography>{subProgram?.endDate}</Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Subvention Rate</Typography>
                    <Typography>
                      {subProgram?.minSubventionIntRate} %
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Subvention Period</Typography>
                    <Typography>
                      {subProgram?.minSubventionPeriodInDays} days
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontWeight='bold'>Margin</Typography>
                    <Typography>{subProgram?.minMargin} %</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Card>
    </>
  );
};

export default Program;
