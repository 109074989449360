import {
  FetchBuyersArgsType,
  FetchBuyersResponseType,
  SupplierIdType,
  BuyersData,
  InvoiceSummaryResponseType,
  InvoiceSummaryData,
  PaymentHistoryReqType,
  PaymentHistoryResponseType,
  PaymentHistoryDataType,
  InvoicesDetailsResponseType,
  InvoicesDetailsDataType,
  InvoicesDetailsArgsType,
  InvoiceListArgsType,
  InvoiceListDataType,
} from 'src/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiBEQueryC1, baseApiBEQuery } from 'src/utils';

export const homeApiC1 = createApi({
  reducerPath: 'home-c1',
  baseQuery: baseApiBEQuery,
  endpoints: (builder) => ({
    fetchBuyers: builder.query<BuyersData, FetchBuyersArgsType>({
      query: (body) => ({
        url: 'user-management/v1/supplier/fetch/buyers',
        method: 'POST',
        body,
      }),
      transformResponse: (response: FetchBuyersResponseType) => {
        return response.data;
      },
    }),
  }),
});

export const homeApi = createApi({
  reducerPath: 'home',
  baseQuery: baseApiBEQueryC1,
  endpoints: (builder) => ({
    fetchInvoiceSummary: builder.query<InvoiceSummaryData, SupplierIdType>({
      query: ({ supplierId }) => ({
        url: `invoice-management/v2/dashboard/invoice-summary/${supplierId}`,
        method: 'GET',
      }),
      transformResponse: (response: InvoiceSummaryResponseType) => {
        return response.data;
      },
    }),
    fetchPaymentHistory: builder.query<
      PaymentHistoryDataType,
      PaymentHistoryReqType
    >({
      query: (body) => ({
        url: 'invoice-management/v2/dashboard/fetch/payment-history',
        method: 'POST',
        body,
      }),
      transformResponse: (response: PaymentHistoryResponseType) => {
        return response.data;
      },
    }),
    fetchInvoicesDetails: builder.query<
      InvoicesDetailsDataType,
      InvoicesDetailsArgsType
    >({
      query: (body) => ({
        url: '/invoice-management/v2/dashboard/fetch/invoice-breakup',
        method: 'POST',
        body,
      }),
      transformResponse: (response: InvoicesDetailsResponseType) => {
        return response.data;
      },
    }),
    uploadInvoiceData: builder.mutation<
      InvoiceListDataType,
      InvoiceListArgsType
    >({
      query: (body) => ({
        url: '/invoice-management/v2/dashboard/invoice-data',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchInvoiceSummaryQuery,
  useFetchPaymentHistoryQuery,
  useFetchInvoicesDetailsQuery,
  useUploadInvoiceDataMutation,
} = homeApi;

export const { useLazyFetchBuyersQuery } = homeApiC1;
