import React, { useState } from 'react';
import { Button, Dialog, Flex, PageHeading } from 'src/components';
import { withPageInternals } from 'src/hoc';
import { Box, useTheme, Card } from '@mui/material';
import Stagged from './Stagged';
import Sent from './Sent';
import { InvitePartiesModal } from 'src/components/Modals';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const Invitations = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isSendInviteModalOpen, setIsSendInviteModalOpen] = useState(false);
  const { spacing } = useTheme();
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    if (newValue !== null) {
      setActiveTab(newValue);
    }
  };
  const toggleModal = () => {
    setIsSendInviteModalOpen(!isSendInviteModalOpen);
  };
  return (
    <>
      <Card className="p-8">
        <Box>
          <Flex justifyContent="space-between">
            <PageHeading>Invitations</PageHeading>
            <Button
              sx={{
                borderRadius: '100px',
                width: 'min-content',
                padding: `0 ${spacing(2)}`,
              }}
              onClick={() => setIsSendInviteModalOpen(true)}
            >
              <PersonAddIcon />
              <div className="px-2">Invite</div>
            </Button>
          </Flex>
          <Flex justifyContent={'space-between'} className="my-5">
            <ToggleButtonGroup
              value={activeTab}
              onChange={onTabChange}
              color="primary"
              exclusive
            >
              <ToggleButton value={0}>Stagged</ToggleButton>
              <ToggleButton value={1}>Sent</ToggleButton>
            </ToggleButtonGroup>
          </Flex>

          {activeTab === 0 && <Stagged />}
          {activeTab === 1 && <Sent />}
          <Dialog
            open={isSendInviteModalOpen}
            onClose={() => setIsSendInviteModalOpen(false)}
            heading="Invite Parties"
          >
            <InvitePartiesModal closeModal={toggleModal} />
          </Dialog>
        </Box>
      </Card>
    </>
  );
};
export default withPageInternals(Invitations);
