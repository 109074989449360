import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

const FetchingSkeleton = () => {
  const { spacing } = useTheme();
  return (
    <>
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={50}
        sx={{ my: spacing(2) }}
      />
      <Skeleton variant='rectangular' width={'100%'} height={50} />
    </>
  );
};

export default FetchingSkeleton;
