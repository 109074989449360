import { RTKFetchError } from 'src/types';
import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'src/redux/store';

export const baseQuery = (baseUrl:string | undefined)=>fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    const {
      user: {
        userBEData: { token ,userId},
      },
    } = getState() as RootState;
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('X-CLIENT-NAME', `PSDU`);
    headers.append('X-USER-ID', userId);
    return headers;
  },
});

type BaseQueryType = BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError | RTKFetchError,
// eslint-disable-next-line @typescript-eslint/ban-types
{},
FetchBaseQueryMeta
>

export const baseApiBEQuery: BaseQueryType = async (args, api, extraOptions) => {
  return await baseQuery(process.env.REACT_APP_BASE_URL_PEMANT)(args, api, extraOptions);
};

export const baseApiBEQueryC1: BaseQueryType = async (args, api, extraOptions) => {
  return await baseQuery(process.env.REACT_APP_BASE_URL_PEMANT_C1)(args, api, extraOptions);
};

