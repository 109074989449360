import {
  FetchAllInvoicesHistoryArgsType,
  AllInvoicesHistoryData,
  FetchAllInvoicesHistoryResponseType,
  FetchAllPaymentsHistoryResponseType,
  AllPaymentsHistoryData,
  FetchAllPaymentsHistoryArgsType,
} from 'src/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiBEQueryC1 } from 'src/utils';

export const invoiceApi = createApi({
  reducerPath: 'invoice',
  baseQuery: baseApiBEQueryC1,
  endpoints: (builder) => ({
    fetchAllInvoicesHistory: builder.query<
      AllInvoicesHistoryData,
      FetchAllInvoicesHistoryArgsType
    >({
      query: ({ supplierId, ...body }) => ({
        url: `invoice-management/v2/dashboard/invoice-list/${supplierId}`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: FetchAllInvoicesHistoryResponseType) => {
        return response.data;
      },
    }),
    fetchAllPaymentHistory: builder.query<
      AllPaymentsHistoryData,
      FetchAllPaymentsHistoryArgsType
    >({
      query: (body) => ({
        url: 'invoice-management/v2/dashboard/fetch/payment-history',
        method: 'POST',
        body,
      }),
      transformResponse: (response: FetchAllPaymentsHistoryResponseType) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazyFetchAllInvoicesHistoryQuery,
  useLazyFetchAllPaymentHistoryQuery,
} = invoiceApi;
