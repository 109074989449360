import { styled, createSvgIcon } from '@mui/material';

const AvatarOutline = createSvgIcon(
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_10_303)'>
      <path
        d='M10.4445 10.1037C12.2854 10.1037 13.7778 8.61129 13.7778 6.77035C13.7778 4.9294 12.2854 3.43701 10.4445 3.43701C8.60351 3.43701 7.11113 4.9294 7.11113 6.77035C7.11113 8.61129 8.60351 10.1037 10.4445 10.1037Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.44446 18.437V16.7703C5.44446 15.8863 5.79565 15.0384 6.42077 14.4133C7.04589 13.7882 7.89374 13.437 8.77779 13.437H12.1111C12.9952 13.437 13.843 13.7882 14.4681 14.4133C15.0933 15.0384 15.4445 15.8863 15.4445 16.7703V18.437'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_10_303'>
        <rect
          width='20'
          height='20'
          fill='white'
          transform='translate(0.678345 20.7983) rotate(-90)'
        />
      </clipPath>
    </defs>
  </svg>,
  'AvatarOutline'
);

export default styled(AvatarOutline)(({ fontSize, color = 'primary' }) => ({
  color: color,
  fontSize: `${fontSize}px !important`,
}));
