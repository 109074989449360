/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Box, SxProps } from '@mui/system';
import get from 'lodash/get';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Skeleton,
  alpha,
  useTheme,
} from '@mui/material';
import { BOX_SHADOW, COLORS } from 'src/theme/constants';

export type TableColumnType<RowType> = {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  render?: (props: CellPropsType<RowType>) => void;
};

export type CellPropsType<RowType> = {
  column: TableColumnType<RowType>;
  row: RowType;
  columnIndex: number;
  rowIndex: number;
  event?: React.MouseEvent<HTMLTableCellElement, MouseEvent>;
  value?: string | boolean | string[];
};

export type RowPropsType<RowType> = {
  row: RowType;
  rowIndex: number;
  event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
};

export type TableProps<RowType> = {
  /**
   * rows or data of the table
   */
  rows: readonly RowType[];
  /**
   * Columns of the table
   */
  columns: TableColumnType<RowType>[];
  /**
   * extra style for the table container or wrapper
   */
  sx?: SxProps;
  /**
   * extra style for the table tag
   */
  sxTableProps?: SxProps;
  /**
   * if we need to make row clickabe to perform some action
   */
  onRowClick?: ({ row, rowIndex, event }: RowPropsType<RowType>) => void;
  /**
   * if we need to make cell clickabe to perform some action
   */
  onCellClick?: ({
    column,
    row,
    rowIndex,
    columnIndex,
    event,
  }: CellPropsType<RowType>) => void;
  /**
   * Set the header sticky.
   *
   * ⚠️ It doesn't work with IE11.
   * @default false
   */
  stickyHeader?: boolean;
  /**
   * to hide the header of the table
   * @default false
   */
  hideTableHead?: boolean;
  paginationComponent?: React.ReactNode;
  isLoading?: boolean;
  numberOfLoadingRows?: number;
  /**
   * Use this class for the tailwind classes(For wrapper)
   */
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const AppTable = <Row extends unknown>({
  sx,
  sxTableProps,
  onRowClick,
  onCellClick,
  rows,
  columns,
  stickyHeader = false,
  hideTableHead = false,
  paginationComponent,
  isLoading = false,
  numberOfLoadingRows = 2,
  className,
}: TableProps<Row>) => {
  const { palette } = useTheme();
  return (
    <TableContainer sx={sx} className={className}>
      <Table
        stickyHeader={stickyHeader}
        aria-label="sticky table"
        sx={sxTableProps}
      >
        {!hideTableHead && (
          <TableHead>
            <TableRow
              sx={{
                background: `${alpha(palette.primary.main, 0.9)} !important`,
                color: COLORS.WHITE,
                textTransform: 'uppercase',
                boxShadow: BOX_SHADOW,
              }}
            >
              {columns.map((column, columnHeadIndex) => (
                <TableCell
                  key={`table-head-${columnHeadIndex}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="!text-white"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {isLoading ? (
            Array.from(Array(numberOfLoadingRows)).map((_, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                {columns.map((_, columnIndex) => (
                  <TableCell key={`cell-${rowIndex}-${columnIndex}`}>
                    <Skeleton height="100%" animation="pulse" />
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : rows.length ? (
            rows.map((row, rowIndex) => {
              return (
                <TableRow
                  key={`row-${rowIndex}`}
                  hover={Boolean(onRowClick)}
                  onClick={
                    onRowClick &&
                    ((
                      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
                    ) => onRowClick({ row, rowIndex, event }))
                  }
                  sx={onRowClick ? { cursor: 'pointer' } : null}
                >
                  {columns.map((column, columnIndex) => {
                    const value = get(row, column.id);
                    return (
                      <TableCell
                        key={`cell-${rowIndex}-${columnIndex}`}
                        align={column.align}
                        sx={onCellClick ? { cursor: 'pointer' } : null}
                        onClick={
                          onCellClick &&
                          ((event) =>
                            onCellClick({
                              column,
                              row,
                              rowIndex,
                              columnIndex,
                              event,
                            }))
                        }
                      >
                        {column.render
                          ? column.render({
                              column,
                              row,
                              rowIndex,
                              columnIndex,
                              value,
                            })
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                className="!text-center !py-4"
                colSpan={columns.length}
              >
                No Data Found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box className="flex justify-end my-6">{paginationComponent}</Box>
    </TableContainer>
  );
};
export default AppTable;
