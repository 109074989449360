import { Download, Flex, Typography } from 'src/components';
import { alpha, ButtonBase, Button, useTheme } from '@mui/material';
import { getAccessToken, getSupplierId, useAppSelector,getAccessUserId } from 'src/redux';
import axios from 'axios';
import React from 'react';
import { API_STATUS_CODE } from 'src/constants';
import { toggleSnackbar, useAppDispatch } from 'src/redux';
import { ERRORS } from 'src/constants';

export default function TemplateDownload() {
  const { palette, spacing } = useTheme();
  const supplierId = useAppSelector(getSupplierId);
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAccessToken);
  const userId = useAppSelector(getAccessUserId);
  const onUploadDocument = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    const formData = new FormData();
    if (files && files?.length > 0) {
      formData.append('file', files[0]);
    }
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/formData',
      'X-CLIENT-NAME': 'PSDU',
      'X-USER-ID': userId
    };
    if (formData) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_PEMANT}/user-management/v1/invitation/send/bulk?supplierId=${supplierId}&type=pemant_template`,
          formData,
          {
            headers,
          }
        )
        .then(({ data }) => {
          if (data.code === API_STATUS_CODE.OK) {
            dispatch(
              toggleSnackbar({
                showSnackBar: true,
                snackBarMessage: ERRORS.EXCEL_UPLOADED,
                snackBarType: 'success',
              })
            );
          } else {
            dispatch(
              toggleSnackbar({
                showSnackBar: true,
                snackBarMessage: data?.message,
                snackBarType: 'error',
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: ERRORS.SOMETHING_WRONG,
              snackBarType: 'error',
            })
          );
        });
    }
  };

  return (
    <>
      <ButtonBase
        sx={{ mb: spacing(2) }}
        href={process.env.REACT_APP_EXCEL_DOWNLOAD_URL || ''}
        download={'Pemant File'}
      >
        <Flex>
          <Download sx={{ color: palette.primary.main, fontSize: 20 }} />
          <Typography
            color='primary'
            sx={{ ml: spacing(1), textDecoration: 'underline' }}
          >
            Click here to download pemant template for bulk upload
          </Typography>
        </Flex>
      </ButtonBase>
      <Button
        variant='contained'
        component='label'
        sx={{
          width: '100%',
          height: 200,
          background: alpha(palette.primary.main, 0.15),
          border: `1px dashed ${palette.primary.main}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: palette.primary.main,
          ':hover': {
            background: alpha(palette.primary.main, 0.25),
          },
        }}
      >
        Upload document here
        <input type='file' hidden onChange={onUploadDocument} />
      </Button>
    </>
  );
}
