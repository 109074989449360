import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import {
  Flex,
  FetchingSkeleton,
  Table,
  TextField,
  Typography,
  BuyerLogo,
  Dialog,
} from 'src/components';
import { INVOICES_FILTER, INVOICES_FILTER_DROPDOWN_ARRAY } from 'src/constants';
import {
  getSupplierId,
  useAppSelector,
  toggleSnackbar,
  useAppDispatch,
} from 'src/redux';
import { ButtonBase, Select, SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { getDMY } from 'src/utils';
import { useLazyFetchBuyerPaymentDetailsQuery } from 'src/redux/services/active-parties';
import { useParams } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { alpha, useTheme } from '@mui/material';
import { COLORS } from 'src/theme/constants';
import { rupeeDecimalFormatter } from 'src/utils';
import { BuyerPaymentsType } from 'src/types';
import { TableColumnType } from 'src/components/common/Table';
import { MultipleInvoices } from 'src/components/Modals';

type PaymentViewProps = {
  startDate: string | null;
  endDate: string | null;
  resetDate: () => void;
};

export default function PaymentView({
  startDate,
  endDate,
  resetDate,
}: PaymentViewProps) {
  const { spacing, palette } = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState<INVOICES_FILTER | string>('');
  const [searchValue, setSearchValue] = useState('');
  const supplierId = useAppSelector(getSupplierId);
  const { userId = '' } = useParams();
  const [multipleInvoiceData, setMultipleInvoiceData] = useState<{
    isVisible: boolean;
    data: BuyerPaymentsType | null;
  }>({
    isVisible: false,
    data: null,
  });
  const dispatch = useAppDispatch();
  const [
    paymentsFetch,
    { data: allPaymentsHistoryData, isFetching: isAllPaymentsHistoryFetching },
  ] = useLazyFetchBuyerPaymentDetailsQuery();

  useEffect(() => {
    paymentsFetch({
      from: startDate ? startDate : '',
      pageNumber,
      searchBy: searchParam,
      to: endDate ? endDate : '',
      supplierId,
      searchValue,
      userId,
    });
  }, [pageNumber]);

  useEffect(() => {
    paymentsFetch({
      from: startDate ? startDate : '',
      pageNumber: 1,
      searchBy: searchParam,
      to: endDate ? endDate : '',
      supplierId,
      searchValue,
      userId,
    });
    setPageNumber(1);
  }, [endDate, startDate]);

  //HANDLERS
  const onFilterChange = (event: SelectChangeEvent) => {
    setSearchParam(event.target.value as INVOICES_FILTER);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPageNumber(1);
  };

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const resetFilters = () => {
    setSearchParam('');
    setSearchValue('');
    setPageNumber(1);
    resetDate();
    paymentsFetch({
      from: '',
      pageNumber: 1,
      searchBy: '',
      to: '',
      supplierId,
      searchValue: '',
      userId,
    });
  };

  const onSearch = () => {
    if (searchParam) {
      paymentsFetch({
        from: startDate ? startDate : '',
        pageNumber,
        searchBy: searchParam,
        to: endDate ? endDate : '',
        supplierId,
        searchValue: searchValue.trim(),
        userId,
      });
    } else {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: 'Please select search type',
          snackBarType: 'error',
        })
      );
    }
  };

  const onMultipleInvoiceClick = (row: BuyerPaymentsType) => {
    setMultipleInvoiceData({
      isVisible: true,
      data: row,
    });
  };

  const resetMultipleInvoice = () => {
    setMultipleInvoiceData({
      isVisible: false,
      data: {
        paymentDate: '',
        utrNumber: '',
        paymentAmount: 0,
        partyName: '',
        listOfInvNumber: [],
        paymentType: '',
      },
    });
  };

  // Table Column :Payment Table
  const paymentHeaders: TableColumnType<BuyerPaymentsType>[] = [
    {
      label: 'Party Name',
      id: 'partyName',
      render: ({ row }) => {
        return (
          <Typography fontSize="0.8rem">
            {row?.partyName ? (
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={row?.partyName} />
                {row?.partyName}
              </Flex>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    {
      label: 'Invoice Number',
      id: 'invoiceNumber',
      render: ({ row }) => {
        const { listOfInvNumber } = row;
        const isMultipleInvoices = listOfInvNumber?.length > 1;
        const { palette } = useTheme();
        return (
          <Flex
            className="gap-2 cursor-pointer"
            onClick={() => onMultipleInvoiceClick(row)}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: COLORS.PRIMARY,
                backgroundColor: alpha(palette.primary.main, 0.2),
                borderRadius: '0.1rem',
              }}
              fontSize="medium"
            />
            <Typography
              color="primary"
              fontWeight="bold"
              className="max-w-[160px] truncate"
              fontSize="0.8rem"
            >
              {listOfInvNumber?.[0]}
            </Typography>
            {isMultipleInvoices && (
              <Typography color="primary" fontWeight="bold">
                +{listOfInvNumber?.length - 1}
              </Typography>
            )}
          </Flex>
        );
      },
    },
    {
      label: 'Payment Type',
      id: 'paymentType',
      render: ({ row }) => {
        return <div className="capitalize">{row?.paymentType || '-'}</div>;
      },
    },
    {
      label: 'Payment amount',
      id: 'paymentAmount',
      render: ({ row }) => {
        if (typeof row?.paymentAmount === 'number') {
          return <div>{rupeeDecimalFormatter(row?.paymentAmount)}</div>;
        }
        return <div>{row?.paymentAmount || '-'}</div>;
      },
    },
    {
      label: 'Payment Date',
      id: 'paymentDate',
      render: ({ row }) => {
        return <div className="capitalize">{row?.paymentDate || '-'}</div>;
      },
    },
    {
      label: 'UTR',
      id: 'utrNumber',
      render: ({ row }) => {
        return <div className="capitalize">{row?.utrNumber || '-'}</div>;
      },
    },
  ];

  return (
    <>
      <Flex justifyContent={'end'}>
        <Flex className="gap-4">
          <Select
            value={searchParam}
            onChange={onFilterChange}
            sx={{
              '.MuiSelect-select': {
                padding: spacing(2),
                width: spacing(22),
                background: 'white',
              },
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              <em>Search By </em>
            </MenuItem>
            {INVOICES_FILTER_DROPDOWN_ARRAY.map((dropdownVal) => {
              return (
                <MenuItem value={dropdownVal.value} key={dropdownVal.value}>
                  {dropdownVal.label}
                </MenuItem>
              );
            })}
          </Select>
          <Box className="relative">
            <TextField
              value={searchValue || ''}
              onChange={onInputChange}
              placeholder="Search"
              className="absolute"
              onKeyDown={(event) => {
                //This event is triggered every time the user presses down any key while selecting the text input field.
                if (event.key === 'Enter') {
                  event.preventDefault(); // Prevent the form from submitting
                  onSearch(); // Call the onSearch function when Enter key is pressed
                }
              }}
              sx={{
                width: '250px',
              }}
            />
            <ButtonBase
              sx={{
                position: 'absolute',
                top: '50%',
                right: 15,
                transform: 'translateY(-50%)',
                color: palette.primary.main,
                fontWeight: 'bold',
                fontSize: '12px',
              }}
              onClick={onSearch}
            >
              Search
            </ButtonBase>
          </Box>
          <Box
            sx={{
              borderRadius: 5,
              p: `${spacing(0.5)} ${spacing(1.5)}`,
              mr: spacing(1.2),
              fontSize: '1rem',
              fontWeight: 'bold',
              color: palette.primary.main,
              backgroundColor: alpha(palette.primary.main, 0.2),
              cursor: 'pointer',
              height: '2.2rem',
              marginTop: '0.6rem',
            }}
            onClick={resetFilters}
          >
            Reset Filter
          </Box>
        </Flex>
      </Flex>
      {isAllPaymentsHistoryFetching ? (
        <FetchingSkeleton />
      ) : (
        <Table
          rows={allPaymentsHistoryData?.listOfPaymentHistory || []}
          columns={paymentHeaders}
          className="mt-4"
          paginationComponent={
            <Pagination
              count={allPaymentsHistoryData?.totalPage}
              page={pageNumber}
              onChange={handleChange}
            />
          }
        />
      )}
      <Dialog
        open={multipleInvoiceData.isVisible}
        onClose={resetMultipleInvoice}
        heading="Payment Details"
        maxWidth="lg"
      >
        <MultipleInvoices invoiceData={multipleInvoiceData.data} />
      </Dialog>
    </>
  );
}
