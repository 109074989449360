export enum INVOICE_STATUS_FILTER_ENUM {
  PAID = 'paid',
  UNPAID = 'unpaid',
  FAILED = 'failed',
}

export enum INVOICE_UPLOAD_MEDIUM_TYPE {
  DEVICE = 'device',
}

export const INVOICE_STATUS_KEY_MAP = {
  [INVOICE_STATUS_FILTER_ENUM.PAID]: 'Paid',
  [INVOICE_STATUS_FILTER_ENUM.UNPAID]: 'Unpaid',
  [INVOICE_STATUS_FILTER_ENUM.FAILED]: 'Failed',
};

export const INVOICE_UPLOAD_MEDIUM_KEY_MAP = {
  [INVOICE_UPLOAD_MEDIUM_TYPE.DEVICE]: 'From Device',
};

export const INVOICE_STATUS_FILTER_ARRAY = [
  {
    label: INVOICE_STATUS_KEY_MAP[INVOICE_STATUS_FILTER_ENUM.UNPAID],
    value: INVOICE_STATUS_FILTER_ENUM.UNPAID,
  },
  {
    label: INVOICE_STATUS_KEY_MAP[INVOICE_STATUS_FILTER_ENUM.PAID],
    value: INVOICE_STATUS_FILTER_ENUM.PAID,
  },
  {
    label: INVOICE_STATUS_KEY_MAP[INVOICE_STATUS_FILTER_ENUM.FAILED],
    value: INVOICE_STATUS_FILTER_ENUM.FAILED,
  },
];

export const INVOICE_UPLOAD_MEDIUM_ARRAY = [
  {
    label: INVOICE_UPLOAD_MEDIUM_KEY_MAP[INVOICE_UPLOAD_MEDIUM_TYPE.DEVICE],
    value: INVOICE_UPLOAD_MEDIUM_TYPE.DEVICE,
  },
];

export enum INVOICES_FILTER {
  INVOICE_NUMBER = 'invoiceNumber',
}

export const INVOICES_FILTER_KEY_MAP = {
  [INVOICES_FILTER.INVOICE_NUMBER]: 'Invoice Number',
};

export const INVOICES_FILTER_DROPDOWN_ARRAY = [
  {
    label: INVOICES_FILTER_KEY_MAP[INVOICES_FILTER.INVOICE_NUMBER],
    value: INVOICES_FILTER.INVOICE_NUMBER,
  },
];

export const PAYMENT_STATUS = {
  PROCESSED: 'processed',
  PROCESSING: 'disbursal_pending_with_lendar',
  PAYOUT_FAILED: 'payout_failed',
  PENDING: 'pending',
};
