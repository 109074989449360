import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { BOX_SHADOW } from 'src/theme';

export const CardWrapper = styled(Card)<{ smallWidth: boolean }>(
  ({ theme: { spacing, breakpoints }, smallWidth }) => ({
    padding: `${spacing(2)} ${spacing(4)}`,
    boxShadow: BOX_SHADOW,
    overflow: 'visible',
    borderRadius: spacing(1),
    ...(smallWidth && {
      [breakpoints.up('md')]: {
        width: '50%',
        margin: `${spacing(10)} auto`,
      },
    }),
  })
);
