import { useTheme } from '@mui/system';
import { Typography } from 'src/components';

const NoDataAvailable = () => {
  const { spacing } = useTheme();
  return (
    <Typography
      fontSize={'2rem'}
      fontWeight={'bold'}
      sx={{
        textAlign: 'center',
        my: spacing(8),
      }}
    >
      No Data available
    </Typography>
  );
};

export default NoDataAvailable;
