import { BUYERS_SEARCH_VALUE_ENUM } from './home';

export const BUYERS_SEARCH_VALUE_ENUM_KEY_MAP = {
  [BUYERS_SEARCH_VALUE_ENUM.ALL]: 'All',
  [BUYERS_SEARCH_VALUE_ENUM.ONBOARDING_COMPLETED]: 'Onboarded',
  [BUYERS_SEARCH_VALUE_ENUM.ONBOARDING_PENDING]: 'Onboarding Pending',
};

export const INVITATIONS_FILTER_ARRAY = [
  {
    label: BUYERS_SEARCH_VALUE_ENUM_KEY_MAP[BUYERS_SEARCH_VALUE_ENUM.ALL],
    value: BUYERS_SEARCH_VALUE_ENUM.ALL,
  },
  {
    label:
      BUYERS_SEARCH_VALUE_ENUM_KEY_MAP[
        BUYERS_SEARCH_VALUE_ENUM.ONBOARDING_COMPLETED
      ],
    value: BUYERS_SEARCH_VALUE_ENUM.ONBOARDING_COMPLETED,
  },
  {
    label:
      BUYERS_SEARCH_VALUE_ENUM_KEY_MAP[
        BUYERS_SEARCH_VALUE_ENUM.ONBOARDING_PENDING
      ],
    value: BUYERS_SEARCH_VALUE_ENUM.ONBOARDING_PENDING,
  },
];
