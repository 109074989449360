import { SvgIconComponent } from '@mui/icons-material';
import { styled, SxProps } from '@mui/material';
import { ColorEnum } from 'src/types';
import { getColorFromTheme } from 'src/utils';

export type IconProps = {
  fontSize?: number;
  color?: ColorEnum;
  className?: string;
  sx?: SxProps;
  Icon: SvgIconComponent;
  onClick?: () => void;
  disableCursorPointer?: boolean;
  onMouseDown?: React.MouseEventHandler<SVGSVGElement>;
};

const MuiIcon: React.FC<IconProps> = ({
  className,
  sx,
  Icon,
  onClick,
  onMouseDown,
}: IconProps) => (
  <Icon
    className={className}
    sx={sx}
    onClick={onClick}
    onMouseDown={onMouseDown}
  />
);

export default styled(MuiIcon)(
  ({ theme, fontSize = 16, color = 'primary' }) => ({
    color: getColorFromTheme(theme, color),
    fontSize: `${fontSize}px !important`,
  })
);
