import { SxProps } from '@mui/material';
import React from 'react';
import { CardWrapper } from './card.styles';

export type CardProps = {
  children: React.ReactNode;
  sx?: SxProps;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  smallWidth?: boolean;
};

const Card = ({ children, sx, onClick, smallWidth = false }: CardProps) => {
  return (
    <CardWrapper sx={sx} onClick={onClick} smallWidth={smallWidth}>
      {children}
    </CardWrapper>
  );
};

export default Card;
