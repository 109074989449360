export const COLORS = {
  PRIMARY: '#4687F4',
  INDIGO: '#4472C4',
  TRANSPARENT: 'transparent',
  GRAY: '#9F9F9F',
  DARK_GRAY: '#717171',
  BLACK: '#2F2F2F',
  WHITE: '#FFFFFF',
  ORANGE: '#FF9C41',
  LIGHT_GRAY: '#D9D9D9',
  GHOST_GRAY: '#8C8C8C',
  SUCCESS: '#058373',
  ERROR: '#C90303',
  DARK_GREEN: '#188865',
  LIGHT_GREEN: '#00B341',
  PINK: '#F97676',
  TURQUOISE: '#5BE0BF',
  LIGHT_BLUE: '#f7f9fc',
  DARK_BOTTLE_GREEN: '#2EBA96',
};

export const FONT_WEIGHTS = {
  LIGHT: 300,
  BOLD: 600,
  REGULAR: 400,
};

export const APP_BAR_HEIGHT = 80;
export const BOX_SHADOW = '2px 4px 10px rgba(156, 155, 155, 0.16)';
