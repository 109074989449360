import {
  SeachBuyerByGSTArgsType,
  SeachBuyerByGSTResponseType,
  SearchByGSTDataType,
  SendInvitationByGSTRespType,
  SendInvitationByGSTArgsType,
  InviteAgainArgsType,
  InviteAgainResponseType,
  BuyerInvitationData,
  buyerInvitationDataArgsType,
  BuyerInvitationDataResponseType,
} from 'src/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiBEQuery } from 'src/utils';

export const invitationsApi = createApi({
  reducerPath: 'invitations',
  baseQuery: baseApiBEQuery,
  endpoints: (builder) => ({
    searchBuyerByGST: builder.query<
      SearchByGSTDataType,
      SeachBuyerByGSTArgsType
    >({
      query: ({ supplierId, GSTNumber }) => ({
        url: `user-management/v1/supplier/search/buyer/${supplierId}/${GSTNumber}`,
        method: 'GET',
      }),
      transformResponse: (response: SeachBuyerByGSTResponseType) => {
        return response.data;
      },
    }),
    sendInvitationByGST: builder.mutation<
      SendInvitationByGSTRespType,
      SendInvitationByGSTArgsType
    >({
      query: (body) => ({
        url: 'user-management/v1/invitation/send',
        method: 'POST',
        body,
      }),
    }),
    inviteAgain: builder.query<InviteAgainResponseType, InviteAgainArgsType>({
      query: ({ supplierId, buyerMobileNumber }) => ({
        url: `user-management/v1/invitation/send/again/${supplierId}/${buyerMobileNumber}`,
        method: 'GET',
      }),
    }),

    buyerInvitationData: builder.query<
      BuyerInvitationData,
      buyerInvitationDataArgsType
    >({
      query: ({ supplierId, buyerMobileNumber }) => ({
        url: `user-management/v1/invitation/details/${supplierId}/${buyerMobileNumber}`,
        method: 'GET',
      }),
      transformResponse: (response: BuyerInvitationDataResponseType) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazySearchBuyerByGSTQuery,
  useSendInvitationByGSTMutation,
  useLazyInviteAgainQuery,
  useBuyerInvitationDataQuery,
} = invitationsApi;
