import { SxProps } from '@mui/material';
import React from 'react';
import { Typography } from '..';

export type PageHeadingProps = {
  children: React.ReactNode;
  sx?: SxProps;
};
const PageHeading = ({ children, sx }: PageHeadingProps) => {
  return (
    <Typography  fontWeight='bold' sx={sx} fontSize='1.25rem'>
      {children}
    </Typography>
  );
};

export default PageHeading;
