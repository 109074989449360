import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INVOICE_STATUS_FILTER_ENUM, INVOICES_FILTER } from 'src/constants';

interface InvoicesState {
  filters: Filters;
}

type Filters = {
  currentFilter: INVOICE_STATUS_FILTER_ENUM;
  startDate: string;
  endDate: string;
  pageNumber: number;
  searchParam: INVOICES_FILTER | '';
  searchValue: string;
};

const initialState = {
  filters: {
    currentFilter: INVOICE_STATUS_FILTER_ENUM.UNPAID,
    startDate: '',
    endDate: '',
    pageNumber: 1,
    searchParam: '',
    searchValue: '',
  },
} as InvoicesState;

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: { ...initialState },
  reducers: {
    onInvoiceFilterChange(state, { payload }: PayloadAction<InvoicesState>) {
      state.filters = payload.filters;
    },
  },
});

export const { onInvoiceFilterChange } = invoicesSlice.actions;
