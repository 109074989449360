import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;

export type DateRangeOnChange = { startDate: Date; endDate: Date };
export type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

type Date = string | null;
type EventValue<DateType> = DateType | null;

type DateRangeProps = {
  startDate: Date;
  endDate: Date;
  disabled?: boolean;
  onChange: (values: DateRangeOnChange, name?: string) => void;
  name?: string;
};

const DateRange = ({
  startDate,
  endDate,
  disabled,
  onChange,
  name,
}: DateRangeProps) => {
  const _onChange = (values: RangeValue<Dayjs>) => {
    if (values) {
      const startDate = values[0]?.format('DD/MM/YYYY') || null;
      const endDate = values[1]?.format('DD/MM/YYYY') || null;
      onChange({ startDate, endDate }, name);
    }
  };

  return (
    <RangePicker
      format="DD/MM/YYYY"
      value={[
        startDate ? dayjs(startDate, 'DD/MM/YYYY') : null,
        endDate ? dayjs(endDate, 'DD/MM/YYYY') : null,
      ]}
      onChange={_onChange}
      disabled={disabled}
    />
  );
};

export default DateRange;
