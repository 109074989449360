import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SORTING_PARAMS } from 'src/constants';

interface HomeState {
  sortingParam: SORTING_PARAMS;
}

const initialState = {
  sortingParam: SORTING_PARAMS.TODAY,
} as HomeState;

export const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    setSortingParam(state, { payload }: PayloadAction<SORTING_PARAMS>) {
      state.sortingParam = payload;
    },
  },
});

export const { setSortingParam } = homeSlice.actions;
