import React, { forwardRef } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import { CssOverrideType } from 'src/types';

export type FlexProps = CssOverrideType &
  Pick<
    StackProps,
    | 'spacing'
    | 'ref'
    | 'justifyContent'
    | 'alignItems'
    | 'alignContent'
    | 'justifySelf'
    | 'alignSelf'
    | 'direction'
    | 'flexWrap'
    | 'onClick'
  > & {
    /**
     * Children of the component
     */
    children?: React.ReactNode;
    /**
     * The flex-grow CSS property sets the flex grow factor of a flex item's main size.
     */
    flexGrow?: number;
    /**
     * The flex-basis CSS property sets the initial main size of a flex item.
     */
    flexBasis?: number | string;
    id?: string;
  };

const FlexWithRef = (
  {
    children,
    justifyContent,
    alignContent,
    alignItems,
    justifySelf,
    alignSelf,
    flexGrow,
    flexBasis,
    spacing,
    direction = 'row',
    flexWrap = 'nowrap',
    className = '',
    sx,
    onClick,
    id,
  }: FlexProps,
  ref: React.Ref<unknown> | undefined
) => (
  <Stack
    id={id}
    ref={ref}
    justifyContent={justifyContent}
    alignContent={alignContent}
    alignItems={alignItems}
    justifySelf={justifySelf}
    alignSelf={alignSelf}
    flexGrow={flexGrow}
    flexBasis={flexBasis}
    spacing={spacing}
    direction={direction}
    sx={sx}
    className={className}
    flexWrap={flexWrap}
    onClick={onClick}
  >
    {children}
  </Stack>
);

export default forwardRef(FlexWithRef);