import React, { useState } from 'react';
import { Flex } from 'src/components';
import { useTheme } from '@mui/material';
import BulkInvite from './BulkInvite';
import SingleInvite from './SingleInvite';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

type InvitePartyModalType = {
  closeModal: () => void;
};

export default function InvitePartyModal({ closeModal }: InvitePartyModalType) {
  const [activeTab, setActiveTab] = useState(0);
  const { spacing } = useTheme();
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Flex
        justifyContent="flex-start"
        sx={{
          margin: `${spacing(3)} 0`,
        }}
      >
        <ToggleButtonGroup
          value={activeTab}
          onChange={onTabChange}
          color="primary"
          exclusive
        >
          <ToggleButton value={0}>Single Invite</ToggleButton>
          <ToggleButton value={1}>Bulk Invite</ToggleButton>
        </ToggleButtonGroup>
      </Flex>
      {activeTab === 0 && <SingleInvite closeModal={closeModal} />}
      {activeTab === 1 && <BulkInvite />}
    </>
  );
}
