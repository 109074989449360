import { styled, createSvgIcon } from '@mui/material';

const ArrowLeftTop = createSvgIcon(
  <svg
    width='30'
    height='28'
    viewBox='0 0 30 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_34_1529)'>
      <path
        d='M4.93225 22.1323V24.6323C4.93225 25.2954 5.18686 25.9312 5.64008 26.4001C6.09329 26.8689 6.70798 27.1323 7.34892 27.1323H21.8489C22.4899 27.1323 23.1045 26.8689 23.5578 26.4001C24.011 25.9312 24.2656 25.2954 24.2656 24.6323V22.1323'
        stroke='#4687F4'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.55725 14.6323L14.5989 20.8823L20.6406 14.6323'
        stroke='#4687F4'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5989 5.88232V20.8823'
        stroke='#4687F4'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_34_1529'>
        <rect
          width='29'
          height='30'
          fill='white'
          transform='translate(0.098877 0.882324)'
        />
      </clipPath>
    </defs>
  </svg>,
  'ArrowLeftTop'
);

export default styled(ArrowLeftTop)(({ fontSize, color = 'primary' }) => ({
  color: color,
  fontSize: `${fontSize}px !important`,
}));
