import { withPageInternals } from 'src/hoc';
import { Card } from '@mui/material';
import { Flex } from 'src/components';

const Dicount = () => {
  return (
    <Card>
      <Flex className="p-8" justifyContent={'center'}>
        Development is under progress !
      </Flex>
    </Card>
  );
};

export default withPageInternals(Dicount);
